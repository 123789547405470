import { RouterProvider } from "react-router-dom";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import { setAuthObj } from "./helper/setAuthObj.helper";
import router from "./shared/router";

function App() {
  const { getIdTokenClaims, logout } = useAuth0();

  setAuthObj(getIdTokenClaims, logout);

  return <RouterProvider router={router} />;
}

export default App;
