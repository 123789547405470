import axios, { AxiosRequestConfig } from "axios";
import getIdToken from "../helper/getIdTokenHelper";
import authObj from "../shared/authObject";
import checkExpiryIdTokenElseLogout from "../helper/checkExpiryIdToken";
import baseUrl from "../shared/baseUrl";

const apiSecureAxios = axios.create({
  baseURL: baseUrl.api,
});

const slackSecureAxios = axios.create({
  baseURL: baseUrl.slack,
});

const notificationSecureAxios = axios.create({
  baseURL: baseUrl.notification,
});

const billingSecureAxios = axios.create({
  baseURL: baseUrl.billing,
});

export const requestHandler = async (config: AxiosRequestConfig) => {
  const idToken = await getIdToken(authObj.getIdTokenClaims!);
  checkExpiryIdTokenElseLogout(idToken);
  config.headers.Authorization = `Bearer ${idToken}`;
  return config;
};

apiSecureAxios.interceptors.request.use(requestHandler);
slackSecureAxios.interceptors.request.use(requestHandler);
notificationSecureAxios.interceptors.request.use(requestHandler);
billingSecureAxios.interceptors.request.use(requestHandler);

export const customAxios = {
  api: apiSecureAxios,
  slack: slackSecureAxios,
  notification: notificationSecureAxios,
  billing: billingSecureAxios,
};
