import { User } from "@auth0/auth0-spa-js";

function capitalizeFirstLetter(string: String) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getNameFromUser = (user?: User) => {
  if (user) {
    if (user.given_name) return capitalizeFirstLetter(user.given_name);
    else if (user.nickname) return capitalizeFirstLetter(user.nickname);
  } else return "User";
};

export default getNameFromUser;
