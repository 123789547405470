import React, { useEffect, useCallback, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import { User, useAuth0 } from "@auth0/auth0-react";
import Popup from "./Popups/Popup";
import { getSubscriptionStatus } from "../services/api/bills.service";
import getNameFromUser from "../helper/getNameFromUser.helper";
import {
  useAccountId,
  useBilling,
  useBillingDetails,
  useBillingLoading,
  useBroker,
  useBrokerage,
  useBrokerageLoading,
  useIBKRStatuses,
  useNotification,
  useNotificationDetails,
  useNotificationLoading,
  usePrimaryAccountId,
  useSub,
  useUserSync,
} from "../store/store";
import { logError } from "../helper/logError.helper";
import { TDAccountsFunction } from "../services/api/accounts.td.service";
import { IBAccountsFunction } from "../services/api/accounts.ib.service";
import {
  BrokerType,
  BrokerageAccount,
  SubscriptionStatus,
  UserType,
} from "../types/types";
import {
  getIBKRAccountId,
  getIBKRSubscriptionStatus,
  getNotificationStatus,
} from "../services/api/notification.service";
import getPrimaryAccountId from "../helper/getPrimaryAccountId.helper";
import { getAccountsFunction } from "../shared/accountFunction";
import { getAlertStatus } from "../services/api/alerts.service";
import { useIsVisible } from "../hooks/useIsVisible";

const NavbarWrapper = () => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const [accountId, setAccountId] = useAccountId();
  const [, setPrimaryAccountId] = usePrimaryAccountId();
  const [, setBillingState] = useBilling();
  const [, setSub] = useSub();
  const [, setIsBrokerageLoading] = useBrokerageLoading();
  const [, setIsNotificationLoading] = useNotificationLoading();
  const [isBillingLoading, setIsBillingLoading] = useBillingLoading();
  const [isUserSync] = useUserSync();
  const [, setNotificationState] = useNotification();
  const [sBrokerage, setBrokerageState] = useBrokerage();
  const [, setBillingDetails] = useBillingDetails();
  const [, setNotificationDetails] = useNotificationDetails();
  const [broker, setBroker] = useBroker();
  const [, setibkrStatuses] = useIBKRStatuses();
  const [, isAccountsVisible] = useIsVisible();
  const navigate = useNavigate();

  const TD = useMemo(() => new TDAccountsFunction(), []);

  const fetchBillingData = useCallback(async () => {
    try {
      const data = await getSubscriptionStatus();
      if (data.status === "active") {
        setBillingState(true);
        setBillingDetails(data);
      }
      // for:trader(3)
      if (data.user_type === UserType.TRADER) {
        setBillingState(true);
        setBillingDetails(data);
      }
    } catch (err) {
      logError(err);
    }
    setIsBillingLoading(false);
  }, [setBillingDetails, setBillingState, setIsBillingLoading]);

  const fetchBrokerageData = useCallback(
    async (user: User) => {
      try {
        let isComplete = false;
        // const p1 = TD.getBrokerageAccounts(user);
        // const p2 = IB.checkAuthStatus(user);
        const p1 = new Promise<{
          accountId: string;
          brokerageAccounts: BrokerageAccount[];
        }>((resolve, err) => {
          throw new Error();
        });
        const p2 = new Promise<{
          accountId: string;
          brokerageAccounts: BrokerageAccount[];
        }>((resolve, err) => {
          throw new Error();
        });

        const result = await Promise.allSettled([p1, p2]);

        if (result[0].status === "fulfilled") {
          setBroker(BrokerType.AMERITRADE);
          setAccountId(result[0].value.accountId);
          setPrimaryAccountId(
            getPrimaryAccountId(result[0].value.brokerageAccounts)
          );
          setBrokerageState(true);
          isComplete = true;
        }
        if (result[1].status === "fulfilled" && result[1].value.accountId) {
          setBroker(BrokerType.INTERACTIVE);
          setAccountId(result[1].value.accountId);
          setPrimaryAccountId(
            getPrimaryAccountId(result[1].value.brokerageAccounts)
          );
          setBrokerageState(true);
          isComplete = true;
        }

        if (!isComplete) throw new Error("User not Authorized to Any Broker");
      } catch (err) {
        setIsNotificationLoading(false); // error in brokerage fetching would lead to notifcation never getting called
        logError(err);
      }
      setIsBrokerageLoading(false);
    },
    [
      setAccountId,
      setBroker,
      setBrokerageState,
      setIsBrokerageLoading,
      setIsNotificationLoading,
      setPrimaryAccountId,
    ]
  );

  const fetchNotificationData = useCallback(
    async (accountId: string) => {
      try {
        const data = await getNotificationStatus(accountId);
        setNotificationState(true);
        setNotificationDetails(data);
      } catch (err) {
        logError(err);
      }
      setIsNotificationLoading(false);
    },
    [setIsNotificationLoading, setNotificationDetails, setNotificationState]
  );

  const fetchAlertData = useCallback(async () => {
    try {
      const data = await getAlertStatus();
      setNotificationState(true);
      setNotificationDetails(data);
    } catch (err) {
      logError(err);
    }
    setIsNotificationLoading(false);
  }, [setIsNotificationLoading, setNotificationDetails, setNotificationState]);

  const fetchBrokerageSubscriptionData = useCallback(
    async (accountId: string, broker: BrokerType) => {
      try {
        const accountFunction = getAccountsFunction(broker);
        const { status } = await accountFunction.checkSubscriptionStatus(
          accountId
        );
        setSub(status);
      } catch (err) {
        logError(err);
      }
    },
    [setSub]
  );

  const fetchIBKRBrokergeData = useCallback(async () => {
    // 1st try catch to decide wether broker IBKR is there or not
    try {
      const { accountId } = await getIBKRAccountId();
      setAccountId(accountId);
    } catch (err) {
      logError(err);
      if (!localStorage.getItem("download_requested")) {
        return;
      }
    }

    // set Broker if first try catch leads to here
    setBroker(BrokerType.INTERACTIVE);
    setBrokerageState(true);

    // 2nd try catch to find about the subscription status of the broker
    try {
      const data = await getIBKRSubscriptionStatus();
      setibkrStatuses(data);
      if (data.authentication && data.orderPrep && data.ibkrConnection) {
        setSub(SubscriptionStatus.SUBSCRIBED);
      }
    } catch (err) {
      logError(err);
    }
  }, [setAccountId, setBroker, setBrokerageState, setSub, setibkrStatuses]);

  const fetchAccountIdForIBKRPeriodic = useCallback(async () => {
    try {
      const { accountId } = await getIBKRAccountId();
      setAccountId(accountId);
    } catch (err) {
      logError(err);
    }
  }, [setAccountId]);

  // 0th call for checking if accountId is available for ibkr
  useEffect(() => {
    let i: NodeJS.Timer;
    if (sBrokerage && broker === BrokerType.INTERACTIVE && !accountId) {
      i = setInterval(fetchAccountIdForIBKRPeriodic, 30 * 1000); // 30 secs
    }
    const j = setTimeout(() => clearInterval(i), 5 * 60 * 1000); // 5 mins;

    return () => {
      clearInterval(i);
      clearTimeout(j);
    };
  }, [accountId, broker, fetchAccountIdForIBKRPeriodic, sBrokerage]);

  // 1st call for => billing
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate("/");
    } else if (!isLoading && isAuthenticated) {
      fetchBillingData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBillingData, isAuthenticated, isLoading]);

  // 2nd call for => accounts
  useEffect(() => {
    if (isAccountsVisible && user) {
      fetchIBKRBrokergeData(); // for IBKR separate call is made unlike TD
      fetchBrokerageData(user);
    }
  }, [fetchBrokerageData, fetchIBKRBrokergeData, isAccountsVisible, user]);

  // 3rd call for => notification and subscription to accounts
  useEffect(() => {
    if (!accountId || !broker) return;
    fetchNotificationData(accountId);
    fetchBrokerageSubscriptionData(accountId, broker);
  }, [
    accountId,
    broker,
    fetchBrokerageSubscriptionData,
    fetchNotificationData,
  ]);

  // 4th call for => alerts only
  useEffect(() => {
    if (isBillingLoading || isAccountsVisible) return;
    fetchAlertData();
  }, [fetchAlertData, isAccountsVisible, isBillingLoading]);

  return isAuthenticated && !isUserSync ? (
    <>
      <Navbar />
      <div className="header-tag">
        Good Morning, <b>{getNameFromUser(user)}</b>
      </div>
      <div className="hr-line"></div>
      <Outlet />
      <Popup /> {/* for now payment conformation popup in Navbar wrapper */}
    </>
  ) : (
    <></>
  );
};

export default NavbarWrapper;
