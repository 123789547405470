import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SuccessPopup from "./SuccessPopup";
import ErrorPopup from "./ErrorPopup";
import VerifyEmailPopup from "./VerifyEmailPopup";
import EmailVerifiedPopup from "./EmailVerifiedPopup";
import IBKRDownloadPopup from "./IBKRDownalodPopup";

enum PopupType {
  SUCCESS = "success",
  FAIL = "fail",
  VERIFY_EMAIL = "verify-email",
  EMAIL_VERIFIED = "email-verified",
  IBKR_DOWNLOAD = "ibkr_download",
}

const Popup = () => {
  const [query, setQuery] = useSearchParams();
  const [isVisible, setIsVisible] = useState(false);
  const [popupType, setPopupType] = useState<PopupType | null>(null);

  useEffect(() => {
    let popupParam = query.get("popup");
    if (popupParam) {
      const p = popupParam as PopupType;
      setPopupType(p); // sets to null if string not in key of PopupType (no popup is shown)
      setIsVisible(true);
    }
  }, [query]);

  const closePopup = () => {
    setIsVisible(false);
    setPopupType(null);
    query.delete("popup");
    setQuery(query, {
      replace: true,
    });
  };

  const switchPopup = () => {
    switch (popupType) {
      case PopupType.SUCCESS:
        return <SuccessPopup closePopup={closePopup} />;
      case PopupType.FAIL:
        return <ErrorPopup closePopup={closePopup} />;
      case PopupType.VERIFY_EMAIL:
        return <VerifyEmailPopup closePopup={closePopup} />;
      case PopupType.EMAIL_VERIFIED:
        return <EmailVerifiedPopup closePopup={closePopup} />;
      case PopupType.IBKR_DOWNLOAD:
        return <IBKRDownloadPopup closePopup={closePopup} />;
      default:
        return <></>;
    }
  };

  return isVisible ? <>{switchPopup()}</> : <></>;
};

export default Popup;
