import { create } from "zustand";
import {
  BrokerType,
  HostStatus,
  IBKRStatuses,
  NotificationDetails,
  SubscriptionMetadata,
  SubscriptionStatus,
} from "../types/types";

interface ConfigState {
  accountId: string | null;
  primaryAccountId: string | null;
  sBrokerage: boolean;
  sBilling: boolean;
  sNotification: boolean;
  sSub: boolean; // although could be of Type Subscription Status, but to confer with other status types
  sAuth: boolean;
  sHost: HostStatus;
  isBrokerageLoading: boolean;
  isNotificationLoading: boolean;
  isBillingLoading: boolean;
  isUserSync: boolean;
  broker: BrokerType | null;
  notificationDetails: NotificationDetails | null;
  billingDetails: SubscriptionMetadata | null;
  ibkrStatuses: IBKRStatuses;
  setAccountId: (val: string | null) => void;
  setPrimaryAccountId: (val: string | null) => void;
  setBrokerageState: (val: boolean) => void;
  setBillingState: (val: boolean) => void;
  setNotificationState: (val: boolean) => void;
  setSubState: (val: SubscriptionStatus) => void;
  setAuthState: (val: boolean) => void;
  setHostState: (val: HostStatus) => void;
  setIsBrokerageLoading: (val: boolean) => void;
  setIsNotificationLoading: (val: boolean) => void;
  setIsBillingLoading: (val: boolean) => void;
  setIsUserSync: (val: boolean) => void;
  setBroker: (val: BrokerType | null) => void;
  setNotificationDetails: (val: NotificationDetails | null) => void;
  setBillingDetails: (val: SubscriptionMetadata | null) => void;
  setibkrStatuses: (val: IBKRStatuses) => void;
}

const useConfigStore = create<ConfigState>()((set) => ({
  accountId: null,
  primaryAccountId: null,
  sBrokerage: false,
  sBilling: false,
  sNotification: false,
  sSub: false,
  sAuth: false,
  sHost: HostStatus.UNAVAILABLE,
  isBrokerageLoading: true,
  isNotificationLoading: true,
  isBillingLoading: true,
  isUserSync: false,
  broker: null,
  notificationDetails: null,
  billingDetails: null,
  ibkrStatuses: {
    authentication: false,
    orderPrep: false,
    ibkrConnection: false,
    payloadType: "",
  },
  setAccountId: (val: string | null) => set({ accountId: val }),
  setPrimaryAccountId: (val: string | null) => set({ primaryAccountId: val }),
  setBrokerageState: (val: boolean) => set({ sBrokerage: val }),
  setBillingState: (val: boolean) => set({ sBilling: val }),
  setNotificationState: (val: boolean) => set({ sNotification: val }),
  setSubState: (val: SubscriptionStatus) =>
    set({ sSub: val === SubscriptionStatus.SUBSCRIBED ? true : false }),
  setAuthState: (val: boolean) => set({ sAuth: val }),
  setHostState: (val: HostStatus) => set({ sHost: val }),
  setIsBrokerageLoading: (val: boolean) => set({ isBrokerageLoading: val }),
  setIsNotificationLoading: (val: boolean) =>
    set({ isNotificationLoading: val }),
  setIsBillingLoading: (val: boolean) => set({ isBillingLoading: val }),
  setIsUserSync: (val: boolean) => set({ isUserSync: val }),
  setBroker: (val: BrokerType | null) => set({ broker: val }),
  setNotificationDetails: (val: NotificationDetails | null) =>
    set({ notificationDetails: val }),
  setBillingDetails: (val: SubscriptionMetadata | null) =>
    set({ billingDetails: val }),
  setibkrStatuses: (val: IBKRStatuses) => set({ ibkrStatuses: val }),
}));

export const useBrokerage = () =>
  useConfigStore(
    (state) => [state.sBrokerage, state.setBrokerageState] as const
  );
export const useBilling = () =>
  useConfigStore((state) => [state.sBilling, state.setBillingState] as const);
export const useNotification = () =>
  useConfigStore(
    (state) => [state.sNotification, state.setNotificationState] as const
  );
export const useSub = () =>
  useConfigStore((state) => [state.sSub, state.setSubState] as const);
export const useAccountId = () =>
  useConfigStore((state) => [state.accountId, state.setAccountId] as const);
export const usePrimaryAccountId = () =>
  useConfigStore(
    (state) => [state.primaryAccountId, state.setPrimaryAccountId] as const
  );
export const useGetConfig = () =>
  useConfigStore(
    (state) =>
      [
        state.sBrokerage,
        state.sBilling,
        state.sNotification,
        state.sSub,
      ] as const
  );
export const useAuth = () =>
  useConfigStore((state) => [state.sAuth, state.setAuthState] as const);
export const useHost = () =>
  useConfigStore((state) => [state.sHost, state.setHostState] as const);
export const useBrokerageLoading = () =>
  useConfigStore(
    (state) => [state.isBrokerageLoading, state.setIsBrokerageLoading] as const
  );
export const useNotificationLoading = () =>
  useConfigStore(
    (state) =>
      [state.isNotificationLoading, state.setIsNotificationLoading] as const
  );
export const useBillingLoading = () =>
  useConfigStore(
    (state) => [state.isBillingLoading, state.setIsBillingLoading] as const
  );
export const useUserSync = () =>
  useConfigStore((state) => [state.isUserSync, state.setIsUserSync] as const);
export const useBroker = () =>
  useConfigStore((state) => [state.broker, state.setBroker] as const);
export const useNotificationDetails = () =>
  useConfigStore(
    (state) =>
      [state.notificationDetails, state.setNotificationDetails] as const
  );
export const useBillingDetails = () =>
  useConfigStore(
    (state) => [state.billingDetails, state.setBillingDetails] as const
  );
export const useIBKRStatuses = () =>
  useConfigStore(
    (state) => [state.ibkrStatuses, state.setibkrStatuses] as const
  );
