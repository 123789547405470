import { z } from "zod";
import { NotificationType } from "../types";

export const getAlertStatusSchema = z
  .object({
    DestinationAddress: z.string().length(10),
    DestinationType: z.custom<NotificationType>(),
  })
  .transform((alertStatus) => ({
    phone: alertStatus.DestinationAddress,
    notification_type: alertStatus.DestinationType,
  }));
