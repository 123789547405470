import React, { useEffect, useState } from "react";
import "./ConfiguredNotification.css";
import {
  useAccountId,
  useNotification,
  useNotificationDetails,
} from "../../../../store/store";
import { disableNotification } from "../../../../services/api/notification.service";
import { logError } from "../../../../helper/logError.helper";
import { normalizeInput } from "../../../../helper/phone.helper";
import { useIsVisible } from "../../../../hooks/useIsVisible";
import { disableAlert } from "../../../../services/api/alerts.service";

type ConfiguredNotificationProps = {
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
};

function ConfiguredNotification({ setIsUpdate }: ConfiguredNotificationProps) {
  const [, setNotificationState] = useNotification();
  const [accountId] = useAccountId();
  const [phone, setPhone] = useState<string | null>(null);
  const [isBusy, setIsBusy] = useState(true);
  const [, isAccountsVisible] = useIsVisible();
  const [notificationDetails, setNotificationDetails] =
    useNotificationDetails();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!notificationDetails) return;
        //check for status -: if (sms then only set phone)
        const { phone } = notificationDetails;

        if (!phone) throw new Error("Phone Number cannot be empty");
        setPhone(phone);
      } catch (err) {
        logError(err);
      }
      setIsBusy(false);
    };

    fetchData();
  }, [notificationDetails]);

  const handleEditNotificationOnClick = async () => {
    try {
      setIsUpdate(true);
      // setNotificationState(false);
    } catch (err) {
      logError(err);
    }
  };

  const handleDeleteNotificationOnClick = async () => {
    try {
      if (!isAccountsVisible) {
        await disableAlert();
      } else {
        if (!accountId) return;
        await disableNotification(accountId);
      }
      setNotificationDetails(null);
      setNotificationState(false);
    } catch (err) {
      logError(err);
    }
  };

  return (
    <div>
      <div className="configured_notificaiton">Configured Notifications</div>
      <div className="notificaiton-received">Receive Notifications on:</div>
      {/* <div className="notifying--card">
        <div>
          <div>Slack</div>
          <div className={`radio ${!isBusy && !phone && "active-green"}`}></div>
        </div>
        {!phone && (
          <div>
            You will receive an invite to a private Slack group to{" "}
            <span>{user && user.email ? user.email : "example@gmail.com"}</span>
          </div>
        )}
      </div> */}
      <div className="notifying--card">
        <div>
          <div>WhatsApp</div>
          <div className={`radio ${!isBusy && phone && "active-green"}`}></div>
        </div>
        {phone && <div className="mobile">{normalizeInput(phone)}</div>}
      </div>
      <div className="edit-notification">
        <button onClick={handleEditNotificationOnClick}>
          Edit Notification
        </button>
        <button onClick={handleDeleteNotificationOnClick}>
          Delete Notification
        </button>
      </div>
    </div>
  );
}

export default ConfiguredNotification;
