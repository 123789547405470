import { createBrowserRouter } from "react-router-dom";
import NavbarWrapper from "../components/NavbarWrapper";
import LandingPage from "../components/Landing/LandingPage";
import Accounts from "../components/Accounts/Accounts";
import Notification from "../components/Accounts/Notification/Notification";
import Brokerage from "../components/Accounts/Brokerage/Brokerage";
import Dashboard from "../components/Dashboard2/Dashboard";
import RegisterMiddleware from "../RegisterMiddleware";
import Billing from "../components/Billing/Billing";
import Terms from "../components/Policy/Terms";
import Privacy from "../components/Policy/Privacy";
import DashboardNew from "../components/NewDashboard/DashboardNew";
import NewLanding from "../components/NewLandingPage/NewLanding";
import Popup from "../components/Popups/Popup";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Popup />
        <NewLanding />
      </>
    ),
  },
  {
    path: "/", // 2 element with same path but one has childrens so best matching happens
    element: <NavbarWrapper />,
    children: [
      {
        path: "usersync",
        element: <RegisterMiddleware />,
      },
      {
        path: "dashboard",
        element: <DashboardNew />,
      },
      {
        path: "accounts",
        element: <Accounts />,
        children: [
          {
            path: "brokerage",
            element: <Brokerage />,
          },
          {
            path: "notification",
            element: <Notification />,
          },
        ],
      },
      {
        path: "billing",
        element: <Billing />,
      },
    ],
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
]);

export default router;
