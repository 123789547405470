import { User } from "@auth0/auth0-spa-js";
import { AxiosInstance } from "axios";
import { z } from "zod";
import { getBrokerageAccountsSchema } from "./response/accounts.type";
import {
  getSubscriptionPlanSchema,
  getSubscriptionStatusSchema,
} from "./response/bills.type";
import { getIBKRSubscriptionStatusSchema } from "./response/notification.type";

export enum AccountKind {
  PRIMARY = "Primary",
  SECONDARY = "Secondary",
}

export enum NotificationType {
  SMS = "SMS",
  SLACK = "SLACK",
  WHATSAPP = "WhatsApp",
}

export enum UserType {
  TRADER = "TRADER",
  CUSTOMER = "CUSTOMER",
}

export enum SubscriptionStatus {
  SUBSCRIBED = "Subscribed",
  UNSUBSCRIBED = "NotSubscribed",
}

export enum SlackAuthStatus {
  AUTHORIZED = "Authorized",
  UNAUTHORIZED = "Unauthorized",
}

export enum BrokerType {
  AMERITRADE = "TD Ameritrade",
  INTERACTIVE = "Interactive Brokers",
}

export enum HostStatus {
  UNAVAILABLE = "Unavailable",
  PROVISIONING = "Provisioning",
  READY = "Ready",
}

export enum AuthStatus {
  PENDING = "Pending",
  SUCCESS = "Success",
  FAILURE = "Failure",
}

export interface BrokerageAccount {
  BrokerageAccountId: string;
  AccountType: string;
  AccountKind: AccountKind;
  AccountValue: string;
  Balance: number;
}

export interface Invoice {
  currency: string;
  paid: boolean;
  invoice_number: string | null;
  receipt_url: string;
  created: number;
  amount_paid: number;
  amount_remaining: number;
  name: string;
}

export type BrokerageAccounts = z.infer<typeof getBrokerageAccountsSchema>;

export type SubscriptionPlan = z.infer<typeof getSubscriptionPlanSchema>;

export type SubscriptionMetadata = z.infer<typeof getSubscriptionStatusSchema>;

export type IBKRStatuses = z.infer<typeof getIBKRSubscriptionStatusSchema>;

export type NotificationDetails = {
  phone: string | null;
  notification_type: NotificationType;
};

export interface IAccountsFunction {
  customAxios: AxiosInstance;
  authorize(user: User, username?: string, password?: string): any;
  checkAuthStatus?(user: User): any;
  getBrokerageAccounts(user: User): Promise<BrokerageAccounts>;
  checkSubscriptionStatus(
    accountId: string
  ): Promise<{ status: SubscriptionStatus }>;
  startTrade(accountId: string): Promise<void>;
  stopTrade(accountId: string): Promise<void>;
  unauthorize(accountId: string): Promise<void>;
}
