import { User } from "@auth0/auth0-spa-js";
import { UserType } from "../../types/types";

export const getChangeUserTypeInput = (userType: UserType, user: User) => {
  return { email: user.email, userType };
};

export const getStartTradeInput = (accountId: string, userType: UserType) => {
  return { userAccountId: accountId, userType };
};

export const getStopTradeInput = (accountId: string, userType: UserType) => {
  return { userAccountId: accountId, userType };
};

export const getIBAuthStatusInput = (userId: string, userType: UserType) => {
  return { userId, userType };
};

export const getIBSubscriptionStatusInput = (
  accountId: string,
  userType: UserType
) => {
  return { accountId, userType };
};

export const getAuthorizeToIBKRInput = (
  username: string,
  password: string,
  userId: string
) => {
  return { username, password, userId };
};
