import React from "react";
import styles from "./Policy.module.css";

const Terms = () => {
  return (
    <div className={`${styles["container"]} ${styles["my-resets"]}`}>
      <h2 className={styles['policy-container']}>Terms and Conditions</h2>
      <p className={styles['same-para']}>
        Welcome to Tradezy! These TERMS OF USE (this <b>"Agreement"</b>) are
        agreed to between Tradezy, a Delaware corporation (<b>"Tradezy"</b>,{" "}
        <b>"we"</b>, <b>"us"</b>, or
        <b>"our"</b>), and you (as <b>"User"</b>, <b>"you"</b> or <b>"your"</b>)
        and govern your use of and access to any website, mobile website, mobile
        application, or other digital system owned by and/or operated for
        Tradezy (collectively, the
        <b>"Platform"</b>) in connection with Tradezy's delivery of its
        trade-alert and order-preparation services, including all products that
        we provide or may provide later (collectively, the <b>"Services"</b>).{" "}
      </p>
      <p className={styles['same-para']}>
        {" "}
        Before becoming a User, and before accessing or using the Platform, you
        are required to agree to the terms of this Agreement. This Agreement is
        the complete and exclusive agreement between you and Tradezy regarding
        your access to and use of the Platform and supersedes any oral or
        written proposal, unsigned agreements, or other communication between
        you and Tradezy regarding your access to and use of the Platform;
        provided, however, specific portions of the Platform or aspects of the
        Services may be governed by additional terms, which will govern in the
        event of a conflict between the terms of this Agreement and such
        additional terms to the extent of such conflict.{" "}
      </p>
      <p className={styles['same-para']}>
        PLEASE CAREFULLY READ THIS AGREEMENT. BY DOWNLOADING, ACCESSING, OR
        USING THE PLATFORM, OR BY CLICKING A BOX THAT STATES THAT YOU ACCEPT OR
        AGREE TO THESE TERMS, YOU AGREE THAT YOU HAVE READ AND AGREE TO BE BOUND
        BY THIS AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT,
        DO NOT DOWNLOAD, ACCESS, OR USE THE PLATFORM OR CLICK THAT YOU ACCEPT OR
        AGREE TO THESE TERMS.{" "}
      </p>
      <p className={styles['same-para']}>
        IF YOU DO NOT AGREE TO THIS AGREEMENT, OR DO NOT MEET THE QUALIFICATIONS
        INCLUDED IN THIS AGREEMENT, TRADEZY IS NOT WILLING TO PROVIDE YOU WITH
        ACCESS TO OR USE OF THE PLATFORM AND YOU MAY NOT ACCESS OR USE THE
        PLATFORM OR ASSOCIATED SERVICES.{" "}
      </p>
      <p className={styles['same-para']}>
        THESE TERMS CONTAIN AN ARBITRATION PROVISION, WHICH LIMITS YOUR RIGHTS
        TO BRING AN ACTION IN COURT AND HAVE DISPUTES DECIDED BY A JUDGE OR
        JURY, AND PROVISIONS THAT LIMIT Tradezy'S LIABILITY TO YOU.{" "}
      </p>
      <ol className={styles["ol-container"]}>
        <li>
          <b>
            <span style={{ fontSize: "24px" }}>T</span>
            <span>ERM</span>.
          </b>{" "}
          This Agreement is entered into as of the earlier of the date you first
          download, access, or use the Platform or associated Services and will
          continue in effect until terminated as set forth herein.
        </li>
        <li>
          <b>
            <span style={{ fontSize: "24px" }}>E</span>
            <span>LIGIBILITY</span>.
          </b>{" "}
          The Platform is intended for use by individuals 18 years of age and
          older. Additionally, to access and use the Platform or particular
          Services, other eligibility requirements may apply, and you may need
          to fulfill certain other legal obligations specific to your
          jurisdiction. Tradezy makes no representation that the Platform is
          appropriate or available for use in all jurisdictions. Accessing any
          part of the Platform is prohibited from any jurisdictions where access
          to or use of the Platform is prohibited.{" "}
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>A</span>
            <span>ccounts</span> and <span style={{ fontSize: "24px" }}>C</span>
            <span>onduct. </span>
          </b>
          <ol>
            <li style={{ marginBottom: "10px" }}>
              <b>Account Creation and Responsibility. </b>
              In order to access certain Services, you may be required to
              establish an account on the Platform (an <b>"Account"</b>).
              Approval of your request to establish an Account is at the sole
              discretion of Tradezy. Each Account and the login credentials or
              device authentication for each Account (the <b>"Account ID"</b>)
              is for your sole use and may only be used by you. You may not use
              an Account ID of another person with the intent to impersonate
              that person. You may not distribute or transfer your Account or
              Account ID or provide a third-party with the right to access your
              Account or Account ID. You are solely responsible for all use of
              the Platform through your Account. All transactions completed
              through your Account or under your Account ID are deemed to have
              been authorized and completed by you. You shall ensure the
              security and confidentiality of your Account ID, and must notify
              Tradezy immediately by email at{" "}
              <a href="mailto:support@tradezyapp.com" rel="no-referrer">
                support@tradezyapp.com
              </a>{" "}
              if any Account or Account ID, or a device associated with same, is
              lost, stolen, improperly accessed, or otherwise compromised. You
              may not share the content of trade alerts or other sensitive
              information from the Platform with any other person without the
              permission of Tradezy.
            </li>
            <li style={{ marginBottom: "10px" }}>
              <b>Third-Party Site Access. </b>
              Your account information may include information about you that we
              obtain from third-party websites, applications, and accounts made
              available to us by you, such as your brokerage account
              credentials, or PayPal or Stripe for payment processing
              (“Registration Information”). If you provide us with login or
              other access credentials to such third-party websites,
              applications, and accounts, you authorize us to use such
              credentials to access Registration Information as your appointed
              attorney-in-fact and agent for the limited purposes of using such
              information as if you were accessing it personally, and in the
              case of your brokerage account, to transmit trade orders that you
              authorize us to transmit to your brokerage. By providing us with
              such credentials for third-party services, you represent and
              warrant to us that any Registration Information obtained by us
              through the use of such credentials is true, correct, and complete
              in all respects. Notwithstanding the foregoing, we are under no
              obligation to monitor, update, maintain, or review any aspect of
              your Registration Information and provide no endorsement or
              guarantee in relation to such information or any third-party
              websites, applications, or accounts by which you make it available
              to us, nor are we responsible for changes made in these accounts
              as a result of permissible actions taken under the Services. For
              the avoidance of doubt, we will never withdraw money from your
              brokerage account, even at your direction and on your behalf.
            </li>
            <li style={{ marginBottom: "10px" }}>
              <b>Identity Verification. </b>Tradezy may require that you verify
              your identity before accessing or using certain Services. Promptly
              following Tradezy's request, you shall provide Tradezy all
              Registration Information and other Content, including your full
              name and any documents or photographs, necessary for Tradezy to
              verify your identity. You hereby represent, warrant and covenant
              that such Content is complete, accurate, and current. Tradezy
              reserves the right to freeze your Account until your identity has
              been verified pursuant to this Section and to terminate your
              Account should you fail to comply with your obligations under this
              Section. You authorize Tradezy and its agents to make such
              investigative inquiries and request such third-party reports as it
              deems necessary to verify your identity.{" "}
            </li>
            <li style={{ marginBottom: "10px" }}>
              <b>Fees. </b>The subscription fee to use the Platform depends on
              your selected subscription tier, and can be charged to your
              balance on a monthly or annual basis. Applicable fees are charged
              to your balance upon activation of your subscription and on the
              first day of each calendar month thereafter for monthly
              subscriptions or the anniversary of your subscription activation
              for annual plans (“Payment Due Date”), and debited from the
              payment source that you designate using our secure online portal
              (“Payment Account”). Additional Platform fees, if any, will vary
              by feature and Service. Except as otherwise provided by law,
              Platform fees are subject to change without notice at any time and
              you are responsible for reviewing and understanding applicable
              fees by reviewing the applicable terms prior to engaging in
              Platform activity. You are responsible for any and all use, sales,
              and other taxes imposed on your access to and use of the Platform.
              <ol>
                <li style={{ marginTop: "5px" }}>Consent to Debit. </li>
                <li>
                  You authorize us to debit your Payment Account on each Payment
                  Due Date for the then-outstanding total of cumulative unpaid
                  subscription fees charged to your Account. You may also
                  authorize us to debit your Payment Account from time to time
                  to pay for other Services on the Platform or for other amounts
                  due, subject to any Agreements relating to each Service or
                  obligation, but never for more than your Account’s
                  then-outstanding balance. You agree that your use of the
                  Platform and of particular Services or features, all requests
                  to debit your account in relation to them, and all electronic
                  records of such requests constitute proof of debit
                  authorization for all legal purposes. These debit
                  authorizations do not constitute an assignment of wages.
                </li>
                <li>How We Handle Electronic Transfers.</li>
                <li>
                  You can make electronic payments through the Tradezy web
                  application (Stripe). If we change a term or condition
                  relating to electronic transfers that would (a) increase fees
                  that you pay, (b) increase your potential liability, (c)
                  reduce the eligible types of electronic transfers, or (d)
                  tighten limits on the amount and frequency of transfers, the
                  change only comes into effect 21 days after we notify you in
                  writing. This does not apply if we have to impose a change for
                  security reasons.
                </li>
                <li>Stop Payments.</li>
                <li>
                  You may stop payment on a preauthorized electronic debit at
                  least three (3) days before the scheduled date of the
                  transfer, by emailing us at{" "}
                  <a href="mailto:support@tradezyapp.com" rel="no-referrer">
                    support@tradezyapp.com
                  </a>
                  . Stopping payment does not remove the obligation to pay
                  amounts due.
                </li>
              </ol>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <b>Your Content. </b>You are solely responsible for all Content
              you provide, upload, submit, post to, make available, or generate
              through access to or use of the Platform (<b>"Your Content"</b>),
              whether through an Account or otherwise, including any
              Registration Information and data about your usage of the Platform
              (“behavioral data”). Your responsibility for Your Content extends
              to resolving any disputes that may arise between you and any other
              User because of Your Content. By providing, uploading, submitting,
              posting, making available, or generating Your Content, you grant
              Tradezy and its authorized representatives and contractors a
              perpetual and non-exclusive right and license to use, process,
              store transmit, and disclose Your Content (a) to provide the
              Services and fulfill other obligations described in this Agreement
              and (b) to further develop and provide services for Tradezy
              customers. You agree to allow us, if we elect in our sole
              discretion, to provide Your Content to third parties in connection
              with identity verification measures as described above, to
              activate or validate your access to the Platform, or for other
              purposes as described in our Privacy Policy. You represent,
              warrant, and covenant that Your Content: (a) does not violate this
              Agreement or any applicable law which is now in effect or may
              hereinafter be enacted; (b) is not libelous, defamatory, obscene,
              abusive, pornographic, threatening, or an invasion of privacy or
              the rights of any third party; (c) does not constitute an
              infringement or misappropriation of the IPR (as defined below) or
              other rights of any third-party; (d) is not an advertisement or
              solicitation of funds, goods, or services; (e) is not false,
              misleading, or inaccurate; and (f) could not be considered junk
              mail, spam, a part of a pyramid scheme, a disruptive commercial
              message, or disruptive advertisement. Tradezy is not responsible
              or liable for any deletion, correction, destruction, damage, loss
              or failure to store or back-up any of Your Content. You agree that
              you have all right, title, interest and consent in Your Content
              necessary to allow Tradezy to use Your Content for the purposes
              for which you provide Your Content to Tradezy. For more
              information on rights and obligations relating to Your Content,
              see our Privacy Policy located at [
              <a href="http://localhost:3000/privacyPolicy">
                http://localhost:3000/privacyPolicy
              </a>
              ] (<b>"Privacy Policy"</b>).
            </li>
            <li style={{ marginBottom: "10px" }}>
              <b>Your Conduct. </b>You agree not to harass, advocate harassment,
              or engage in any conduct that is abusive or harmful to any other
              person or illegal under applicable law. We reserve the right, but
              are not obligated, to investigate or prohibit any conduct, or
              remove or refuse to post any Content (including Your Content) that
              we deem in our sole discretion to be unlawful; in breach of this
              Agreement; violative of the rights of others; otherwise offensive
              to you, the Platform, other Users, any other person, or our
              rights; or that may be harmful to others, our operations, or
              reputation. We assume no liability and shall not be liable
              directly or indirectly for any action or inaction with respect to
              your conduct, communication, transactions, or Content.
              Additionally, we may disclose any Content or electronic
              communication of any kind: (i) to satisfy any law or request by a
              governmental authority; (ii) if such disclosure is necessary or
              appropriate to operate the Platform; (iii) to protect our rights
              or property, our Users and customers, you, or any other person; or
              (iv) if, in our sole discretion, such Content or electronic
              communication should be referred to law enforcement or other
              government authorities. Furthermore, in addition to removing any
              of Your Content, Tradezy reserves the right to suspend or remove
              you from the Platform as described in Section 6.
            </li>
            <li>
              <b>Compliance Verification. </b>Upon the request of Tradezy or its
              designee, you agree to provide to Tradezy promptly such
              information as Tradezy may request regarding your installation and
              use of the Platform and related materials in order to allow
              Tradezy to verify compliance with this Agreement. On request, you
              agree to create, retain, and provide to Tradezy and its auditors
              accurate written records, system tool outputs, and other system
              information sufficient to provide auditable verification that your
              use of the Platform and related materials is and has been in
              compliance with this Agreement, including, without limitation, all
              of Tradezy’s applicable licensing and pricing terms, and
              compliance with Tradezy’s IPR (as defined below). You agree to run
              scripts and tools requested by Tradezy and to report the results
              thereof to Tradezy as part of such verification. Upon reasonable
              notice, Tradezy or its designee may verify your compliance with
              the terms of this Agreement for all environments in which you or
              anyone acting on your behalf uses or installs the Platform
              software or related materials. For such verification, you give
              Tradezy access to your equipment, including the right to inspect
              all copies of the Platform and related materials in your
              possession or use. Tradezy or its designee may use an independent
              auditor to assist with such verification, provided Tradezy has a
              written confidentiality agreement in place with such auditor that
              protects your rights under this Agreement and our Privacy Policy.
            </li>
          </ol>
        </li>
        <li>
          <b>
            <span style={{ fontSize: "24px" }}>A</span>
            <span>ccess. </span>
          </b>
          <ol>
            <li style={{ marginBottom: "10px" }}>
              <b>To the Platform. </b>
              Subject to your compliance with this Agreement and satisfaction of
              associated eligibility criteria, including timely payment of the
              applicable monthly or annual subscription fee, Tradezy permits you
              to access and use the Platform solely for lawful purposes in
              accordance with the terms of this Agreement and any other
              agreement you make with us before being given access to any
              specific areas of the Platform or particular Services.
            </li>
            <li style={{ marginBottom: "10px" }}>
              <b>To Content. </b>Unless otherwise noted on the Platform, other
              than Your Content, all Content available through the Platform (
              <b>“Platform Content”</b> or <b>“Content”</b>) is owned by
              Tradezy, the Users providing that Content, or Tradezy's other
              Content providers. Subject to your compliance with this Agreement,
              you may access the Platform Content solely for your own purposes
              in connection with your own use of the Platform. You may not, and
              may not permit any third-party to: (a) alter, modify, reproduce,
              or create derivative works of any Platform Content; (b)
              distribute, sell, resell, convey, lend, loan, lease, license,
              sublicense, gift, or transfer any Platform Content; or (c) alter,
              modify, obscure or remove any copyright, trademark or any other
              notices that are provided on or in connection with any Platform
              Content. Except as expressly described in this Agreement or as
              required by applicable law, Tradezy has not verified the accuracy
              of, and shall not be responsible for any errors or omissions in,
              any Platform Content. Without limiting the foregoing, Tradezy
              shall not be held liable to you or any other third-party for any
              Platform Content (or any other Content, including Your Content)
              under any law which is now in effect or may hereafter be enacted,
              including the Communications Decency Act (“CDA”), 47 U.S.C. § 230.
              Except as set forth in this Agreement, you are granted no licenses
              or rights in or to any Platform Content, or any IPR (as defined
              below) therein or related thereto.
            </li>
            <li style={{ marginBottom: "10px" }}>
              <b>To Services. </b>Access to certain features of the Platform or
              particular Services may require you to agree to meet additional
              eligibility criteria and to enter into additional terms governing
              such additional features or Services. In the event of a conflict
              between the terms of this Agreement and such additional Platform
              or Service terms, such additional terms shall control to the
              extent of the conflict.{" "}
            </li>
            <li style={{ marginBottom: "10px" }}>
              <b>To Third-Party Sites. </b>The Platform may contain links to
              third-party sites that are not under the control of Tradezy.
              Unless otherwise noted, any other site or application accessed
              from the Platform is independent from us, and we have no control
              over and are not responsible for its content. Links to third-party
              websites or applications are provided for your convenience only
              and you access them solely at your own risk. You acknowledge and
              agree that Tradezy shall not be liable or responsible, directly or
              indirectly, for any damage or loss caused or alleged to be caused
              by or related to the use of or reliance on any content, goods, or
              services available through any third-party website or resource.
              Your access and use of the third-party sites and applications are
              governed by the terms of use and privacy policies of these
              third-party sites.
            </li>
            <li style={{ marginBottom: "10px" }}>
              <b>To Third-Party Services. </b>
              The Platform may also provide you with the option to use certain
              Platform features which may require you to access services,
              content, functionality, software, and other things developed,
              provided, or maintained by third party service providers
              (collectively, <b>“Third Party Services”</b>). All Third Party
              Services are provided by third parties and are not under the
              direction or control of Tradezy. You acknowledge and agree that
              Tradezy shall not be liable or responsible, directly or
              indirectly, for your access to or use of any Third Party Services,
              including any damages, losses, liabilities, failures, or problems
              caused by, related to, or arising from any Third Party Services.
              Your use of and access to any Third Party Services is solely
              between you and the third party provider of the Third Party
              Services. Your access to and use of any Third Party Services is
              subject to any additional terms, conditions, agreements, or
              privacy policies provided or entered into in connection with the
              Third Party Services (each, a <b>“Third Party Agreement”</b>). The
              terms of any Third Party Agreement (which may include payment of
              additional fees) apply to the applicable Third Party Services
              provided under that Third Party Agreement but do not otherwise
              apply to your access to or use of the Platform. Except as set
              forth in this Agreement, in the event of a conflict between the
              terms of this Agreement and a Third Party Agreement, the terms of
              the Third Party Agreement shall control with respect to your
              access to and use of any Third Party Services provided under that
              Third Party Agreement to the extent of such conflict. This
              Agreement will continue to control in all other respects.
            </li>
          </ol>
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>T</span>
            <span>ermination. </span>
          </b>
          This Agreement may be terminated by Tradezy at any time, with or
          without notice to you except as required by applicable law. You may
          terminate this Agreement by discontinuing use of the Platform and all
          associated Services (including deleting any Platform copies or other
          materials in your possession), provided you also have paid all fees or
          other amounts owed to Tradezy under this Agreement or other
          agreements. If you have outstanding amounts owed, you may not
          terminate this Agreement until these are paid. Upon termination of
          this Agreement for any reason: (1) all rights granted to you under
          this Agreement shall terminate; (2) you will immediately cease all use
          of and access to the Platform and all Services, including Your Content
          and any Platform Content you obtained prior to termination; (3) your
          outstanding obligations under this Agreement and any Service terms
          shall survive; and (4) Tradezy may, in its sole discretion, delete
          your Account or Your Content at any time. Sections 3 (Accounts and
          Conduct), 4 (Access), 5 (Termination), 7 (Platform Technology), 8
          (Ownership), 9 (Additional Mobile Application Terms), 10 (Feedback),
          12 (Additional Representations and Warranties), 13 (Disclaimers), 14
          (Indemnity), 15 (Limitation on Liability), 16 (Data Privacy), 17
          (Telecommunications Consent), 18 (Claims of Infringement), 19 (Force
          Majeure), 20 (Arbitration), 21 (Governing Law and Venue), 22
          (Notices), 23 (Interpretation) and 25 (Additional Terms) will survive
          any expiration or termination of this Agreement, as will any other
          provisions that give rise to a party's ongoing obligations.
          Notwithstanding anything to the contrary elsewhere in this Agreement,
          Tradezy may terminate this Agreement and any of your rights hereunder
          immediately if it has the right to suspend activity under Section 6
          hereof.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>S</span>
            <span>uspension. </span>
          </b>
          Without limiting Tradezy's right to terminate this Agreement, Tradezy
          may also suspend your access to your Account and the Platform
          (including Your Content), with or without notice to you, in its sole
          and absolute discretion, upon any actual, threatened, or suspected
          breach of this Agreement or applicable law or upon any other conduct
          deemed by Tradezy to be inappropriate or detrimental to Tradezy, the
          Platform, or any other Tradezy customer or User.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>P</span>
            <span>latform </span>
            <span style={{ fontSize: "24px" }}>T</span>
            <span>echnology. </span>
          </b>
          The Platform, and the databases, software, hardware, and other
          technology used by or on behalf of Tradezy to operate the Platform,
          and the structure, organization, and underlying data, information, and
          software code thereof (collectively, the <b>“Technology”</b>),
          constitute valuable intellectual property of Tradezy and its licensors
          and vendors. You may not, and may not permit any third-party to: (1)
          access or attempt to access the Technology except as expressly
          provided in this Agreement; (2) use the Technology, whether directly
          or indirectly, in any unlawful manner or in any other manner that
          could damage, disable, overburden, or impair the Technology; (3) use
          automated scripts to collect information from or otherwise interact
          with the Technology, unless the Platform expressly makes available
          APIs or other data feeds and then only to interact with such as
          intended; (4) alter, modify, reproduce, change, or in any way create
          derivative works of the Technology; (5) distribute, sell, resell,
          gift, lend, loan, lease, license, sublicense, or transfer any of your
          rights to access or use the Technology or otherwise make the
          Technology available to any third-party; (6) reverse engineer,
          disassemble, decompile, or otherwise attempt to derive, determine, or
          discover the method of operation of the Technology; (7) attempt,
          whether directly or indirectly, to circumvent or overcome any
          technological protection measures intended to restrict access to any
          portion of the Technology; (8) monitor the availability, performance,
          or functionality of the Technology; or (9) interfere, whether directly
          or indirectly, with the operation or hosting of the Technology.
          Tradezy uses commercially reasonable means to protect the security of
          the Platform, but you acknowledge that perfect security on the
          internet and mobile systems is impossible and that, as a result, Your
          Content may be exposed in the event of a breach. Tradezy cannot be
          responsible for the performance or maintenance of the computer or
          mobile device operating systems that you employ, or for bugs, viruses,
          spyware, and other malware found on your devices. Your operating
          system requires occasional security patches, updates, and service
          packs. You should ensure that whenever possible it is set to update
          automatically and that it is receiving and installing updates and
          other maintenance releases. We also recommend that you install
          antivirus software where possible. You are responsible for any
          internet, mobile, wireless, data, or similar rates and fees that may
          apply to the hardware, software, and other equipment you use to access
          the Platform.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>O</span>
            <span>wnership. </span>
          </b>
          Tradezy retains all rights, title and interest, including, without
          limitation, all IPR (as defined below), in and to the Technology and
          any additions, improvements, updates and modifications thereto. You
          receive no ownership interest in or to the Technology and you are not
          granted any right or license to use the Technology itself apart from
          your ability to access the Platform pursuant to this Agreement
          including, as applicable, by downloading or installing associated
          software on a limited, revocable, nonexclusive, nontransferable basis.
          The Tradezy name, logo and all product and service names associated
          with the Platform are intellectual property of Tradezy and its
          licensors and providers and you are granted no right or license to use
          them. For purposes of this Agreement, “IPR” means all intellectual
          property rights, proprietary rights, rights of publicity, rights of
          privacy, and all other legal rights protecting data, information or
          intangible property throughout the world, including, without
          limitation, any and all copyrights, trademarks, service marks, trade
          secrets, patent rights, moral rights, sui generis rights in databases,
          and contract rights.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>A</span>
            <span>dditional </span>
            <span style={{ fontSize: "24px" }}>M</span>
            <span>obile </span>
            <span style={{ fontSize: "24px" }}>A</span>
            <span>pplication </span>
            <span style={{ fontSize: "24px" }}>T</span>
            <span>erms. </span>
          </b>
          <ol>
            <li style={{ marginBottom: "10px" }}>
              <b>Generally. </b> In instances where the Platform is made
              available to you via mobile application on a compatible device,
              you acknowledge that this Agreement is concluded solely between
              you and Tradezy; and Tradezy, not Apple, Samsung, Google, or any
              other App store or download source, is solely responsible for the
              Platform and the content thereof. “Apple,” “Samsung,” and “Google”
              are trademarks of the respective third-party owners of such
              rights. In accordance with all other terms of this Agreement, your
              authorized use of the Platform and related materials through a
              mobile application includes the right to store, load, execute and
              maintain the programs and related materials, in object code only,
              on one or more partitions on devices approved to download and use
              the Platform. You acknowledge that Apple, Samsung, Google, or any
              other application store or download source has no obligation
              whatsoever to furnish any maintenance and support services and has
              no warranty obligation with respect to the Platform.
            </li>
            <li style={{ marginBottom: "10px" }}>
              <b>Apple Devices. </b> 9.2For use on Apple devices, your Platform
              license is limited to a nontransferable license to use the
              Platform on Apple products that you own or control and as
              permitted by the Usage Rules set forth in the Apple App Store
              Terms of Service, except that the Platform may be accessed and
              used by other accounts associated with you via family sharing or
              volume purchasing. To the maximum extent permitted by applicable
              law, Apple will have no warranty obligation, consumer protection
              liability, or responsibility for any intellectual property
              infringement claim whatsoever with respect to the Platform. You
              acknowledge and agree that Apple, and Apple's subsidiaries, are
              third party beneficiaries of this Agreement, and that, upon your
              acceptance of this Agreement, Apple has the right (and is deemed
              to have accepted the right) to enforce this Agreement against you
              as a third party beneficiary hereof.
            </li>
          </ol>
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>F</span>
            <span>eedback. </span>
          </b>
          You agree that any feedback or ideas you provide to Tradezy regarding
          the Platform or its underlying Technology or Service or any suggested
          improvements thereto (collectively, the <b>“Feedback”</b>) are the
          exclusive property of Tradezy. To the extent you own any rights in the
          Feedback, you hereby agree to, and hereby do, assign all right, title,
          and interest in and to the Feedback to Tradezy. You agree to perform
          all acts reasonably requested by Tradezy to perfect and enforce such
          rights.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>A</span>
            <span>vailability. </span>
          </b>
          Tradezy will use reasonable efforts to ensure that the Platform will
          function in accordance with this Agreement; however, Tradezy does not
          guarantee that the Platform will be available at all times. Tradezy
          will make reasonable efforts to give you notice of planned
          maintenance. You accept the risks associated with the fact that you
          may not always be able to receive notifications from the Platform or
          engage in activity using your Account.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>A</span>
            <span>dditional </span>
            <span style={{ fontSize: "24px" }}>R</span>
            <span>epresentations </span>
            <span>AND</span>
            <span style={{ fontSize: "24px" }}>W</span>
            <span>arranties. </span>
          </b>
          <ol>
            <li style={{ marginBottom: "10px" }}>
              <b>General. </b> You hereby represent and warrant to Tradezy that:
              (a) you have the legal right and authority to enter into this
              Agreement; (b) this Agreement forms a binding legal obligation on
              your behalf; and (c) you have the legal right and authority to
              perform your obligations under this Agreement and to grant the
              rights and licenses described in this Agreement.
            </li>
            <li style={{ marginBottom: "10px" }}>
              <b>Compliance with Laws. </b>
              12.2You acknowledge that the Platform is a general-purpose online
              service and is not specifically designed to achieve any specific
              objective you may have or facilitate your compliance with any
              specific law. Your use of the Platform in compliance with any
              specific law, rule, or regulation applicable to you, or other data
              or information you may provide or generate through the Platform is
              your sole responsibility. Tradezy is not responsible for your
              compliance with any such law or for your failure to comply.
              Regardless of the jurisdiction in which you use or access the
              Platform, you represent and warrant to Tradezy that your use of
              and access to the Platform, including, without limitation, Your
              Content and any other data or information you may provide or
              generate through your use of or access to the Platform, complies
              with all applicable laws, rules, and regulations and does not
              cause Tradezy itself to violate any applicable law. The foregoing
              includes compliance with all laws that are applicable to the
              transmission of data on the internet, including, but not limited
              to, laws governing the transmission of data or funds across
              international boundaries, into prohibited countries, and
              containing financial, technical, or personally identifiable
              information. You represent and warrant that (i) you are not
              located in a country that is subject to a U.S. Government embargo,
              or that has been designated by the U.S. Government as a “terrorist
              supporting” country; and (ii) you are not listed on any U.S.
              Government list of prohibited or restricted parties. You
              represent, warrant and covenant that you shall not make, provide,
              receive or attempt to use the Platform to make, provide, or
              receive payments from or to any person or Entity that is subject
              to any U.S. sanctions administered by the Office of Foreign Assets
              Control of the U.S. Treasury Department. You acknowledge that the
              Platform may be subject to restrictions and controls imposed by
              the United States Export Administration Act (the “Act”) and the
              regulations thereunder. You agree and certify that neither the
              Platform nor any direct product thereof is being or will be
              acquired, shipped, transferred, or re-exported, directly or
              indirectly, into any country prohibited by the Act and the
              regulations thereunder, or is used will be used for any purposes
              prohibited by the same.
            </li>
          </ol>
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>D</span>
            <span>isclaimers</span>.
          </b>
          <ol>
            <li style={{ marginBottom: "10px" }}>
              <b>No Tax, Legal, Financial, or Investment Advice. </b> 13.1You
              should not interpret any Content provided on the Platform as tax,
              legal, financial, or investment advice. We have no special
              relationship with or fiduciary duty to you and your use of the
              Platform does not create such a relationship. You agree and
              acknowledge that you are solely responsible for conducting legal,
              accounting, and other due diligence review on the information
              posted on the Platform. Tradezy is not an investment adviser and
              does not provide or adopt investment recommendations of any kind,
              and merely provides information about other people’s trades
              without any implication that such trades are appropriate for your
              personal situation or risk tolerance. Messages from the Platform
              should not be relied upon in place of your independent judgment.
              Messages about particular investments do not constitute a
              recommendation to buy or sell such investments, and discussions of
              past performance are not predictions of future performance.
              Tradezy expressly disclaims any warranty, express or implied, that
              investments identified by the Platform will be profitable or meet
              any particular investment criteria. You assume full responsibility
              for any trades that you authorize. Trades you authorize are
              unlikely to have the same cost basis or sale prices as similar
              trades by other people, due to market fluctuations and execution
              differences.
            </li>
            <li style={{ marginBottom: "10px" }}>
              <b>General. </b> THE PLATFORM IS PROVIDED “AS IS” AND “AS
              AVAILABLE.” TRADEZY AND ITS PROVIDERS DO NOT WARRANT OR GUARANTEE
              THE ACCURACY, COMPLETENESS, AVAILABILITY, ADEQUACY, OR CURRENCY OF
              ANY SERVICES OR CONTENT AND DO NOT ENDORSE THE VIEWS OR OPINIONS
              THAT MAY BE EXPRESSED OR PROVIDED THROUGH THE PLATFORM. TRADEZY
              AND ITS PROVIDERS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND
              REPRESENTATIONS OF ANY KIND WITH REGARD TO THE PLATFORM AND THIS
              AGREEMENT, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING,
              WITHOUT LIMITATION, ANY WARRANTIES OF FITNESS FOR A PARTICULAR
              PURPOSE, MERCHANTABILITY, TITLE, OR NON-INFRINGEMENT. TRADEZY AND
              ITS PROVIDERS DO NOT WARRANT THE RELIABILITY, ACCURACY, INTEGRITY,
              SECURITY, COMPLETENESS, ADEQUACY OR CURRENCY OF THE PLATFORM, AND
              DO NOT ENDORSE THE VIEWS OR OPINIONS THAT MAY BE EXPRESSED OR
              PROVIDED BY USERS OF THE PLATFORM. NO ORAL OR WRITTEN INFORMATION
              GIVEN BY THE PLATFORM, TRADEZY OR ITS EMPLOYEES, PROVIDERS, OR
              AGENTS SHALL CREATE A WARRANTY OF ANY KIND. TRADEZY AND ITS
              PROVIDERS SPECIFICALLY DISCLAIM ANY REPRESENTATION OR WARRANTY
              THAT THE PLATFORM WILL MEET YOUR REQUIREMENTS, BE SUITABLE FOR THE
              INTENDED PURPOSE, OR OPERATE UNINTERRUPTED OR ERROR FREE. IN
              PARTICULAR, YOU ACKNOWLEDGE THAT IF TRADEZY'S SOFTWARE VENDORS
              SUFFER AN INTERRUPTION OF SERVICE, TRADEZY'S PLATFORM WILL NOT
              FUNCTION CORRECTLY AND MAY NOT EXECUTE TRADES THAT YOU AUTHORIZE.
            </li>
          </ol>
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>I</span>
            <span>ndemnity. </span>
          </b>
          You hereby agree to indemnify, defend, and hold harmless Tradezy and
          its officers, directors, shareholders, affiliates, employees, agents,
          contractors, assigns, Users, customers, providers, licensees, and
          successors in interest (<b>“Indemnified Parties”</b>) from any and all
          claims, losses, liabilities, damages, fees, expenses and costs
          (including attorneys' fees, court costs, damage awards, and settlement
          amounts) that result from any claim or allegation against any
          Indemnified Party arising in any manner from: (1) your access to or
          use of the Platform, whether through an Account or otherwise; (2) Your
          Content, Registration Information, Account information, or other
          Content you provide through the Platform; or (3) your breach of any
          representation, warranty, or other provision of this Agreement.
          Tradezy shall provide you with notice of any such claim or allegation,
          and Tradezy has the right to participate in the defense of any such
          claim at its expense.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>L</span>
            <span>imitation </span>
            <span>ON </span>
            <span style={{ fontSize: "24px" }}>L</span>
            <span>iability. </span>
          </b>
          TRADEZY SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
          EXEMPLARY OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, UNDER ANY THEORY
          OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY OR TORT (INCLUDING
          NEGLIGENCE OR OTHERWISE), ARISING IN CONNECTION WITH OR OUT OF THE USE
          OF THE PLATFORM, EVEN IF TRADEZY HAS BEEN ADVISED OF THE POSSIBILITY
          OF SUCH DAMAGES, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF YOUR
          CONTENT, DATA, OPPORTUNITY, REVENUES OR PROFITS, BUSINESS
          INTERRUPTION, OR PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES.
          Tradezy'S TOTAL CUMULATIVE LIABILITY TO YOU IN CONNECTION WITH THIS
          AGREEMENT AND ITS PROVISION OF THE PLATFORM UNDER THIS AGREEMENT,
          WHETHER IN CONTRACT OR TORT OR OTHERWISE, SHALL NOT EXCEED THE FEES
          TRADEZY ACTUALLY RECEIVED IN CONNECTION WITH YOUR USE OF THE PLATFORM
          IN THE TWELVE MONTHS PRECEDING THE EVENTS ON WHICH ANY BASIS FOR
          LIABILITY IS ALLEGED. YOU AGREE THAT TRADEZY WOULD NOT ENTER INTO THIS
          AGREEMENT WITHOUT THESE LIMITATIONS ON ITS LIABILITY. IN JURISDICTIONS
          WHERE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES
          IS NOT PERMITTED, Tradezy'S LIABILITY IS LIMITED TO THE MAXIMUM EXTENT
          PERMITTED BY LAW.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>D</span>
            <span>ata </span>
            <span style={{ fontSize: "24px" }}>P</span>
            <span>rivacy. </span>
          </b>
          You expressly consent to the use and disclosure of your personally
          identifiable information and Your Content as described in our{" "}
          <a href="http://localhost:3000/privacyPolicy" rel="no-referrer">
            Privacy Policy
          </a>
          . Notwithstanding anything in our{" "}
          <span style={{ background: "yellow" }}>Privacy Policy</span>, Tradezy
          may collect, extract, compile, synthesize, and analyze non-personally
          identifiable data or information resulting from your access to and use
          of the Platform. To the extent any such non-personally identifiable
          data or information is collected or generated by Tradezy, the data and
          information is solely owned by Tradezy and may be used by Tradezy for
          any lawful business purpose without a duty of accounting to you,
          provided that the data and information is used only in an aggregated
          form, without directly identifying you or any other entity or natural
          person as the source thereof.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>C</span>
            <span>ONSENT </span>
            <span>TO </span>
            <span style={{ fontSize: "24px" }}>E</span>
            <span>LECTRONIC </span>
            <span style={{ fontSize: "24px" }}>C</span>
            <span>OMMUNICATIONS. </span>
          </b>
          You expressly consent to receiving your communications in an
          electronic form that complies with the E-SIGN Act instead of on paper,
          and warrant that your device meets the technical requirements to
          receive our communications. You may request paper documents by
          contacting us.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>T</span>
            <span>elecommunications </span>
            <span style={{ fontSize: "24px" }}>C</span>
            <span>onsent. </span>
          </b>
          You agree to be contacted by Tradezy electronically, on the phone, or
          otherwise, for purposes related to this Agreement.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>C</span>
            <span>laims </span>
            <span>of </span>
            <span style={{ fontSize: "24px" }}>I</span>
            <span>nfringement. </span>
          </b>
          Tradezy respects your copyrights and other intellectual property
          rights and those of other third parties. If you believe in good faith
          that your copyrighted work has been reproduced on Platform without
          your authorization in a way that constitutes copyright infringement,
          you may notify us by mail at the address specified in Section 22
          (Notices). Please include the following information in your
          correspondence: (1) the identity of the infringed work, and of the
          allegedly infringing work; (2) your name, address, daytime phone
          number, and email address, if available; (3) a statement that you have
          a good-faith belief that the use of the copyrighted work is not
          authorized by the owner, his or her agent, or the law; (4) a statement
          that the information in the notification is accurate and, under
          penalty of perjury, that you are authorized to act on behalf of the
          owner; and (5) your electronic or physical signature.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>F</span>
            <span>orce </span>
            <span style={{ fontSize: "24px" }}>M</span>
            <span>ajeure. </span>
          </b>
          Tradezy is not responsible for performance of its obligations
          hereunder where delayed or hindered by events beyond its reasonable
          control, including, without limitation, acts of God or any
          governmental authority (including regulation, enforcement, controls or
          restrictions on any Service), war or national emergency, riots or
          insurrection, sabotage, embargo, fire, flood, accident, strike or
          other labor disturbance, or interruption of or delay in systems, power
          or telecommunications under third-party control.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>A</span>
            <span>rbitration. </span>
          </b>
          You understand that this Section (“Arbitration Section”) is a part of
          the Agreement and it affects your rights. It contains A JURY TRIAL
          WAIVER and procedures for MANDATORY BINDING ARBITRATION AND A CLASS
          ACTION WAIVER.
          <ol>
            <li>
              <i>Arbitration Section. </i> Before asserting a claim in any
              proceeding, you agree that you shall engage in a good faith
              attempt to resolve the claim. All claims and disputes arising out
              of or relating to this Agreement that cannot be resolved
              informally or in small claims court shall be resolved by binding
              arbitration on an individual basis under the terms of this
              Arbitration Section. The Arbitration Section applies to both you
              and us, including your and our respective assigns, representatives
              and/or agents, as to all matters which arise out of or relate to
              this Agreement or any resulting transaction or relationship.
            </li>
            <li>
              <i>Waiver of Jury Trial. </i> YOU HEREBY WAIVE YOUR CONSTITUTIONAL
              AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A
              JUDGE OR A JURY, instead electing that all claims and disputes
              shall be resolved by arbitration under this Arbitration Section.
              Arbitration procedures are typically more limited, more efficient
              and less costly than rules applicable in court and are subject to
              very limited review by a court.
            </li>
            <li>
              <i>Arbitration Rules. </i> 21.3The Federal Arbitration Act governs
              the interpretation and enforcement of this dispute resolution
              provision. Arbitration shall be initiated through JAMS, Inc., an
              established alternative dispute resolution provider (“ADR
              Provider”). If the selected ADR Provider is not available to
              arbitrate, we shall select an alternative ADR Provider. The rules
              of the ADR Provider govern all aspects of this arbitration, except
              to the extent such rules are in conflict with the Agreement or to
              the extent that application of the Agreement provisions would
              result in the unenforceability of this Arbitration Section. The
              JAMS rules governing the arbitration are available online at
              www.jamsadr.com or by calling JAMS at 1-800-352-5267. Any
              arbitration hearing will be held in our federal judicial district.
              Any judgment on the award rendered by the arbitrator may be
              entered in any court of competent jurisdiction.{" "}
            </li>
            <li>
              <i>Decision of Arbitrator. </i> The arbitrator shall issue a
              written award and statement of decision describing the essential
              findings and conclusions on which the award is based. The
              arbitrator has the same authority to award relief on an individual
              basis that a judge in a court of law would have. The award of the
              arbitrator is final and binding upon you and us.
            </li>
            <li>
              <i>Waiver of Class or Consolidated Actions. </i> ALL CLAIMS AND
              DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION SECTION MUST BE
              ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND
              ARBITRATION CLAIMS OF MORE THAN ONE BORROWER CANNOT BE ARBITRATED
              OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER
              BORROWER.
            </li>
            <li>
              <i>Severability. </i> If any part or parts of this Arbitration
              Section other than the Waiver of Class or Consolidated Actions are
              found under the law to be invalid or unenforceable, then such
              specific part or parts shall be of no force and effect and shall
              be severed and the remainder of the Arbitration Section shall
              continue in full force and effect. If the Waiver of Class or
              Consolidated Actions is found to be unenforceable, then as to the
              specific dispute in which that ruling of unenforceability was
              made, this entire Arbitration Section shall be unenforceable.
            </li>
            <li>
              <i>Survival of Agreement. </i> This Arbitration Section shall
              survive the termination of this Agreement.
            </li>
            <li>
              <i>Small Claims Court. </i> Notwithstanding the foregoing, you or
              we may bring an individual action in small claims court.
            </li>
            <li>
              <i>Your Right to Opt Out of Arbitration. </i>
              You may opt out of this Arbitration Section by sending a signed
              written notice of your election to do so, within thirty (30) days
              of the date of your entering into this Agreement. Such notice
              shall be sent to Tradezy at Tradezy's address listed below.
            </li>
          </ol>
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>G</span>
            <span>overning </span>
            <span style={{ fontSize: "24px" }}>L</span>
            <span>aw </span>
            <span>AND </span>
            <span style={{ fontSize: "24px" }}>V</span>
            <span>enue. </span>
          </b>
          The interpretation of the rights and obligations of the parties under
          this Agreement, including, to the extent applicable, any negotiations,
          arbitrations or other proceedings hereunder, will be governed in all
          respects exclusively by the laws of the State of Delaware, U.S.A. as
          such laws apply to contracts between New York residents performed
          entirely within New York without regard to the choice or conflict of
          law principles of any jurisdiction. Subject to Section 21
          (Arbitration), each party hereto: (a) consents to and waives any
          objections to personal jurisdiction, service of process, and venue in
          the federal and state courts located in the State of Delaware, U.S.A.
          and (b) agrees that any action arising out of or relating to this
          Agreement shall be filed and prosecuted only in such courts.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>N</span>
            <span>otices. </span>
          </b>
          Unless otherwise specified in this Agreement, any notices required or
          allowed under this Agreement and any complaints will be provided to
          Tradezy by email at support@tradezyapp.com. Tradezy may provide you
          with any notices required or allowed under this Agreement by
          contacting you at any email or postal mailing address you provide to
          Tradezy, at any telephone number you provide to Tradezy (including by
          SMS text), or by posting such notice to the Platform. Notices provided
          to Tradezy are deemed given when actually received by Tradezy. Notice
          provided to you is deemed given upon transmission to your email or
          telephone, upon posting to the Platform, or, in the case of postal
          mail, upon two (2) business days of transmission. You agree to notify
          us immediately if there is any change to your email or postal mailing
          address or telephone number. Such notice will take effect after we
          have had a reasonable opportunity to process it.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>I</span>
            <span>nterpretation. </span>
          </b>
          Terms used in this Agreement have the definitions given in this
          Agreement or, if not defined in this Agreement, have their plain
          English meaning as commonly interpreted in the United States. To the
          extent any translated version of this Agreement conflicts with the
          English language version, the English language version will control.
          When interpreting this Agreement: (1) any headings are for reference
          purposes only and shall not be used in the construction and
          interpretation of this Agreement; (2) the singular includes the
          plural, and vice versa; (2) “includes”, “including”, “for example”,
          “such as” and similar terms are not words of limitation; (3) no rule
          of construction applies to the disadvantage of a party because that
          party was responsible for the preparation of this Agreement; (4) “law”
          means any foreign, federal, state or local law (including common law),
          statute, standard, code, ordinance, rule, regulation, promulgation or
          any order by any governmental authority; and (5) “governmental
          authority” means any government or governmental or regulatory body
          thereof, or political subdivision thereof, whether federal, state,
          local or foreign, or any agency, instrumentality or authority thereof,
          or any court or arbitrator (public or private).
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>M</span>
            <span>odifications. </span>
          </b>
          Tradezy reserves the right, at any time, to modify the Platform, as
          well as the terms of this Agreement, whether by making those
          modifications available on the Platform or by providing notice to you
          as specified in this Agreement. Any modifications will be effective
          upon posting to the Platform or delivery of such other notice, unless
          otherwise required by law. You are deemed to have agreed to any and
          all modifications through your continued use of the Platform following
          such notice.
        </li>
        <li>
          <b style={{ textTransform: "uppercase" }}>
            <span style={{ fontSize: "24px" }}>A</span>
            <span>dditional </span>
            <span style={{ fontSize: "24px" }}>T</span>
            <span>erms. </span>
          </b>
          All waivers by Tradezy under this Agreement must be in writing or
          later acknowledged by Tradezy in writing. Any waiver or failure by
          Tradezy to enforce any provision of this Agreement on one occasion
          shall not be deemed a waiver by Tradezy of any other provision or of
          such provision on any other occasion. If any provision of this
          Agreement is held to be unenforceable, that provision shall be removed
          to the extent necessary to comply with the law, replaced by a
          provision that most closely approximates the original intent and
          economic effect of the original to the extent consistent with the law,
          and the remaining provisions shall remain in full force. The
          prevailing party in any lawsuit or proceeding arising from or related
          to this Agreement shall be entitled to receive its costs, expert
          witness fees, and reasonable attorneys' fees, including costs and fees
          on appeal. You may not assign or transfer either this Agreement or any
          of your rights or obligations hereunder (in whole or in part and
          including by sale, merger, consolidation, gift, or other operation of
          law) without the prior written approval of Tradezy. Any assignment in
          violation of the foregoing is null and void. Tradezy may assign this
          Agreement to any party that assumes Tradezy's obligations hereunder.
          The parties hereto are independent contractors, not agents, employees
          or employers of the other, or joint venturers, and neither acquires
          hereunder any right or ability to bind or enter into any obligation on
          behalf of the other.
        </li>
      </ol>
    </div>
  );
};

export default Terms;
