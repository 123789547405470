import React, { useEffect, useMemo } from "react";
import AccountsFunction from "./Configured/AccountsFunction";
import SetupBrokerage from "./Setup/SetupBrokerage";
import AuthBrokerage from "./Auth/AuthBrokerage";
import {
  useAuth,
  useBroker,
  useBrokerage,
  useBrokerageLoading,
  useHost,
} from "../../../store/store";
import { IBAccountsFunction } from "../../../services/api/accounts.ib.service";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthStatus, BrokerType, HostStatus } from "../../../types/types";
import { logError } from "../../../helper/logError.helper";
import { useIsVisible } from "../../../hooks/useIsVisible";
import IBKRStatus from "./IBKRStatus/IBKRStatus";

const Brokerage = () => {
  const [sBrokerage] = useBrokerage();
  const [broker] = useBroker();
  const [sAuth, setAuthState] = useAuth();
  const [, setHostState] = useHost();
  const [isBrokerageLoading] = useBrokerageLoading();
  const { user } = useAuth0();
  const [, isAccountsVisible] = useIsVisible();

  const IB = useMemo(() => new IBAccountsFunction(), []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user) return;
        const { authStatus } = await IB.checkAuthStatus(user);
        if (authStatus === AuthStatus.PENDING) {
          setHostState(HostStatus.PROVISIONING);
          setAuthState(true);
        }
      } catch (err) {
        logError(err);
      }
    };

    // fetchData();   \\ commented to remove ib
  }, [IB, setAuthState, setHostState, user]);

  return !isBrokerageLoading && isAccountsVisible ? (
    <>
      {/* state 1 */}
      {!sBrokerage && !sAuth && <SetupBrokerage />}

      {/* state 2 */}
      {!sBrokerage && sAuth && <AuthBrokerage />}

      {/* state 3 */}
      {sBrokerage && broker === BrokerType.AMERITRADE && <AccountsFunction />}

      {/* state 4 */}
      {sBrokerage && broker === BrokerType.INTERACTIVE && <IBKRStatus />}
    </>
  ) : (
    <></>
  );
};

export default Brokerage;
