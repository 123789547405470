import { IdToken, LogoutOptions } from "@auth0/auth0-spa-js";

export type GetIdTokenClaims = () => Promise<IdToken | undefined>;
export type LogOutFunc = (options?: LogoutOptions | undefined) => void;

let authObj: {
  getIdTokenClaims: GetIdTokenClaims | null;
  logoutFunc: LogOutFunc | null;
} = {
  getIdTokenClaims: null,
  logoutFunc: null,
};

export default authObj;
