import React, { useEffect, useRef, useState } from "react";
import "./SetupNotification.css";
import {
  convertPhoneToString,
  normalizeInput,
  validatePhoneInput,
} from "../../../../helper/phone.helper";
import { logError } from "../../../../helper/logError.helper";
import {
  enableNotification,
  getNotificationStatus,
  updateNotification,
} from "../../../../services/api/notification.service";
import {
  useAccountId,
  useNotification,
  useNotificationDetails,
} from "../../../../store/store";
import { NotificationDetails, NotificationType } from "../../../../types/types";
import { useIsVisible } from "../../../../hooks/useIsVisible";
import {
  enableAlert,
  getAlertStatus,
  updateAlert,
} from "../../../../services/api/alerts.service";

type SetupNotificationProps = {
  useIsUpdate: {
    isUpdate: boolean;
    setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  };
};

function SetupNotification({ useIsUpdate }: SetupNotificationProps) {
  const [selected, setSelected] = useState<0 | 1 | 2>(0);
  const [inviteSent, setInviteSent] = useState(false);
  const [resend, setResend] = useState(false);
  const [smsSent, setSmsSent] = useState(false);
  const [phone, setPhone] = useState<string>("");
  const [, isAccountsVisible] = useIsVisible();
  const [, setNotificationState] = useNotification();
  const { isUpdate, setIsUpdate } = useIsUpdate;
  const [accountId] = useAccountId();
  const resendTimerRef = useRef<HTMLButtonElement>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [notificationDetails, setNotificationDetails] =
    useNotificationDetails();

  useEffect(() => {
    const setPhoneDetails = () => {
      try {
        if (!notificationDetails) return;
        if (!notificationDetails.phone)
          throw new Error("Phone Number cannot be empty");

        setPhone(normalizeInput(notificationDetails.phone));
      } catch (err) {
        logError(err);
      }
      setIsDisabled(false);
    };

    if (isUpdate) {
      setSelected(2);
      setPhoneDetails();
    } // for whatsapp, selected === 2
  }, [isUpdate, notificationDetails]);

  const handleSelection = (id: 0 | 1 | 2) => {
    setSelected(id);
  };

  const handleSendInvite = async () => {
    try {
      if (!accountId) return;
      await enableNotification(accountId, NotificationType.SLACK);
      setInviteSent(true);
      setResend(true);
      await handleTimer();
      setInviteSent(false);
      // make sNotification true and take to relevant page
    } catch (err) {
      logError(err);
    }
  };

  const delay = (delayInms: number) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };

  const handleTimer = async () => {
    let timeToCount = 20;

    while (timeToCount) {
      if (!resendTimerRef.current) {
        await delay(100);
        continue;
      }

      resendTimerRef.current.innerText = `Resend in 00:${timeToCount
        .toString()
        .padStart(2, "0")}`;

      timeToCount--;
      await delay(1000);
    }
  };

  const handleEnableSMS = async () => {
    try {
      // check for phone number and valid format
      validatePhoneInput(phone);
      // convert to normal string
      const normPhone = convertPhoneToString(phone);
      // enable sms here
      // 2 blocks for alert and notification
      let notificationDetails: NotificationDetails;

      if (!isAccountsVisible) {
        if (isUpdate) {
          await updateAlert(NotificationType.WHATSAPP, normPhone);
          setIsUpdate(false);
        } else {
          await enableAlert(NotificationType.WHATSAPP, normPhone);
        }
        notificationDetails = await getAlertStatus();
      } else {
        if (!accountId) return;
        if (isUpdate) {
          await updateNotification(accountId, NotificationType.SMS, normPhone);
          setIsUpdate(false);
        } else {
          await enableNotification(accountId, NotificationType.SMS, normPhone);
        }
        notificationDetails = await getNotificationStatus(accountId);
      }

      setSmsSent(true);
      // make sNotification true and take to relevant page
      setNotificationDetails(notificationDetails); // still contains accountId, but working neverthless
      setNotificationState(true);
    } catch (err) {
      logError(err);
    }
  };

  const handlePhoneInput = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(normalizeInput(value));
  };

  return (
    <div className="notification--wrapper">
      {/* <div
        className={`notifier__ ${selected === 1 ? "notifier--active" : ""}`}
        onClick={() => handleSelection(1)}
      >
        <div className="rounded-__box_">
          <div className="rounded-__box_-ext"></div>
          <div className="rounded-__box_-int"></div>
        </div>
        <div className="slack-notify">
          <div>Slack</div>
          <p>
            You will receive an invite to a private Slack group to{" "}
            <span>{user ? user.email : "example@gmail.com"}</span>
          </p>

          {selected === 1 && !inviteSent && (
            <div className="anchor-containerd">
              <button onClick={handleSendInvite}>
                {resend ? "Resend" : "Send Invite"}
              </button>
            </div>
          )}

          {selected === 1 && inviteSent && (
            <>
              <div className="invite-send-notify">Invite Sent!</div>
              <div className="resend-link">
                <button id="timer" ref={resendTimerRef}></button>
              </div>
            </>
          )}
        </div>
      </div> */}
      <div
        className={`notifier__ ${selected === 2 ? "notifier--active" : ""}`}
        onClick={() => handleSelection(2)}
      >
        <div className="rounded-__box_">
          <div className="rounded-__box_-ext"></div>
          <div className="rounded-__box_-int"></div>
        </div>
        <div className="sms-notify">
          <div>WhatsApp</div>
          {/* <p>(standard SMS charges will apply)</p> */}

          {selected === 2 && (
            <div className="input--box__">
              <input
                type="text"
                placeholder="eg: (555) 123-456"
                value={phone}
                onChange={handlePhoneInput}
                disabled={isUpdate && isDisabled}
              />
            </div>
          )}

          {selected === 2 && !smsSent && (
            <div className="enable-button">
              <button onClick={handleEnableSMS}>
                {isUpdate ? "Update" : "Enable"} WhatsApp Notification
              </button>
            </div>
          )}

          {selected === 2 && smsSent && (
            <div className="success---message">
              Great! WhatsApp Notifications have been enabled.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SetupNotification;
