import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useCallback } from "react";
import {
  getUserIfAlreadyExist,
  registerUserToBackend,
} from "./services/api/user.service";
import { logError } from "./helper/logError.helper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useUserSync } from "./store/store";

const RegisterMiddleware = () => {
  const { isAuthenticated, user, logout, getIdTokenClaims } = useAuth0();
  const [, setIsUserSync] = useUserSync();
  const navigate = useNavigate();

  setIsUserSync(true);

  const registerUser = useCallback(async (user) => {
    try {
      const { userId, userType } = await getUserIfAlreadyExist(user);
      localStorage.setItem("userId", userId);
      localStorage.setItem("userType", userType);
    } catch (err) {
      const { userId, userType } = await registerUserToBackend(user);
      localStorage.setItem("userId", userId);
      localStorage.setItem("userType", userType);
    }
  }, []);

  useEffect(() => {
    getIdTokenClaims().then((res) => {
      if (!res) return;
      localStorage.setItem("idToken", res.__raw);
    });
    if (isAuthenticated && user) {
      registerUser(user)
        .then(() => {
          setIsUserSync(false);
          navigate("/dashboard");
        })
        .catch((err) => {
          logError(err);
          let logOutUrl = window.location.origin;

          if (axios.isAxiosError(err)) {
            if (err.response?.data && !err.response.data.EmailVerified) {
              logOutUrl += "?popup=verify-email";
            }
          }
          localStorage.clear();
          logout({
            logoutParams: {
              returnTo: `${logOutUrl}`,
            },
          });
        });
    }
  }, [
    isAuthenticated,
    registerUser,
    user,
    navigate,
    logout,
    getIdTokenClaims,
    setIsUserSync,
  ]);

  return <></>;
};

export default RegisterMiddleware;
