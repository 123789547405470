import React from "react";
import { useBilling, useBillingLoading } from "../../store/store";
import ConfiguredBilling from "./Configured/ConfiguredBilling";
import SetupBilling from "./Setup/SetupBilling";
import { useIsVisible } from "../../hooks/useIsVisible";

// const checkIcon = (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//   >
//     <rect width="24" height="24" rx="12" fill="#5652E9" />
//     <path
//       d="M9.83959 17.0813C9.70826 17.0814 9.57819 17.0556 9.45686 17.0053C9.33553 16.9551 9.22533 16.8813 9.13259 16.7883L5.29259 12.9483C5.19708 12.8561 5.1209 12.7457 5.06849 12.6237C5.01608 12.5017 4.9885 12.3705 4.98734 12.2377C4.98619 12.1049 5.01149 11.9732 5.06177 11.8504C5.11205 11.7275 5.18631 11.6158 5.2802 11.5219C5.37409 11.428 5.48574 11.3538 5.60864 11.3035C5.73154 11.2532 5.86321 11.2279 5.99599 11.2291C6.12877 11.2302 6.25999 11.2578 6.382 11.3102C6.504 11.3626 6.61435 11.4388 6.70659 11.5343L9.83959 14.6673L17.2926 7.21431C17.4812 7.03215 17.7338 6.93135 17.996 6.93363C18.2582 6.93591 18.509 7.04108 18.6944 7.22649C18.8798 7.4119 18.985 7.66271 18.9873 7.9249C18.9895 8.1871 18.8888 8.4397 18.7066 8.62831L10.5466 16.7883C10.4539 16.8813 10.3437 16.9551 10.2223 17.0053C10.101 17.0556 9.97093 17.0814 9.83959 17.0813Z"
//       fill="white"
//     />
//   </svg>
// );

const Billing = () => {
  const [sBilling] = useBilling();
  const [isBillingLoading] = useBillingLoading();
  const [isBillingVisible] = useIsVisible();

  return !isBillingLoading && isBillingVisible ? (
    sBilling ? (
      <ConfiguredBilling />
    ) : (
      <SetupBilling />
    )
  ) : (
    <></>
  );
};

export default Billing;
