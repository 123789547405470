import { User } from "@auth0/auth0-spa-js";
import * as inputs from "../../helper/api/notification.helper";
import { customAxios } from "../axios.service";
import { NotificationType } from "../../types/types";
import * as schema from "../../types/response/notification.type";

// 1
export const authorizeToSlack = async (user: User, accountId: string) => {
  console.log("Authorizing to Slack...");

  const formData = inputs.getAuthorizeToSlackInput(user, accountId);
  await customAxios.notification.post("authorize", formData);

  console.log("Authorized to Slack Succesfully!");
};

// 2
export const unauthorizeToSlack = async (accountId: string) => {
  console.log("Unauthorizing to Slack...");

  const formData = inputs.getUnauthorizeToSlackInput(accountId);
  await customAxios.notification.post("unauthorize", formData);

  console.log("Unauthorized to Slack Successfully!");
};

// 3
export const checkSlackAuthStatus = async (accountId: string) => {
  console.log("Checking Auth Status...");

  const formData = inputs.getCheckSlackAuthStatusInput(accountId);
  const { data } = await customAxios.notification.post("status", formData);
  let d = schema.slackAuthStatusSchema.parse(data);

  return d;
};

// 4
export const checkSlackUserExists = async (user: User, accountId: string) => {
  console.log("Checking if the user Exists in Slack...");

  const formData = inputs.getSlackUserExistsInput(accountId, user);
  const { data } = await customAxios.notification.post("user/exists", formData);
  let d = schema.slackUserExistsSchema.parse(data);

  return d;
};

//5
export const joinNewUserToSlack = async (user: User, accountId: string) => {
  console.log("Requesting Slack to accept new User...");

  const formData = inputs.getSlackNewUserJoinRequestInput(accountId, user);
  const { data } = await customAxios.notification.post("request", formData);
  let d = schema.slackJoinUserSchema.parse(data);

  return d;
};

// 6
export const getNotificationServiceHealth = async () => {
  const { data } = await customAxios.notification.get("health");
  return data;
};

// 7
export const enableNotification = async (
  accountId: string,
  notificationType: NotificationType,
  phone = ""
) => {
  const formData = inputs.getNotificationEnableOrUpdateInput(
    accountId,
    notificationType,
    phone
  );
  const { data } = await customAxios.notification.post("enable", formData);
  let d = schema.enableNotificationSchema.parse(data);

  return d;
};

// 8
export const updateNotification = async (
  accountId: string,
  notificationType: NotificationType,
  phone = ""
) => {
  const formData = inputs.getNotificationEnableOrUpdateInput(
    accountId,
    notificationType,
    phone
  );
  const { data } = await customAxios.notification.post("update", formData);
  let d = schema.updateNotificationSchema.parse(data);

  return d;
};

// 9
export const disableNotification = async (accountId: string) => {
  const formData = inputs.getNotificationDisableInput(accountId);
  const { data } = await customAxios.notification.post("disable", formData);
  let d = schema.disableNotificationSchema.parse(data);

  return d;
};

// 10
export const getNotificationStatus = async (accountId: string) => {
  const formData = inputs.getNotificationStateCheckInput(accountId);
  const { data } = await customAxios.notification.post("status", formData);
  let d = schema.notificationStatusSchema.parse(data);

  return d;
};

// 11
export const deletePortfolio = async (accountId: string) => {
  const formData = inputs.getDeletePortfolioInput(accountId);
  const { data } = await customAxios.notification.post(
    "portfolio/delete",
    formData
  );
  // TODO: add return data checking here
  return data;
};

// 12
export const getIBKRSubscriptionStatus = async () => {
  const { data } = await customAxios.notification.get("ibkr/subscription");
  let d = schema.getIBKRSubscriptionStatusSchema.parse(data);

  return d;
};

// 13
export const getIBKRDownloadLinks = async () => {
  const { data } = await customAxios.notification.get("ibkr/download");
  let d = schema.getIBKRDownloadLinksSchema.parse(data);
  return d;
};

// 14
export const getIBKRAccountId = async () => {
  const { data } = await customAxios.notification.get("ibkr/retrieve");
  let d = schema.getIBKRAccountIdSchema.parse(data);

  return d;
};
