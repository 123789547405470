import React from "react";
import "./ErrorNotification.css";
import { useNavigate } from "react-router-dom";
import { useBilling, useBrokerage, useSub } from "../../../../store/store";
import { useIsVisible } from "../../../../hooks/useIsVisible";

const ErrorNotification = () => {
  const [sBilling] = useBilling();
  const [sSub] = useSub();
  const [, isAccountsVisible] = useIsVisible();

  const navigate = useNavigate();
  const handleErrorNavigation = () => {
    if (sBilling) navigate("/accounts/brokerage");
    else {
      navigate("/billing");
    }
  };

  return (
    <>
      <div className="notificaiton-error-container">
        {isAccountsVisible && !sSub && (
          <div className="error--container">
            <div className="error--name">
              ALERT: Brokerage has not been configured
            </div>
            <div className="error--solution">
              Please go to Accounts page to configure your Brokerage settings.
              Your copy account will not be enabled until this step is complete.
            </div>
          </div>
        )}
        {!sBilling && (
          <div className="error--container">
            <div className="error--name">
              ALERT: Billing details have not been configured
            </div>
            <div className="error--solution">
              Please go to Billing page to configure your Billing details. Your
              copy account will not be enabled until this step is complete.
            </div>
          </div>
        )}
      </div>
      <button
        disabled={!sBilling}
        className={`btn__sky ${!sBilling ? "disabled-btn" : ""}`}
        onClick={handleErrorNavigation}
      >
        <div>{sBilling ? "Account" : "Billing"}</div>
        <div>
          <svg
            width="115"
            height="8"
            viewBox="0 0 115 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 1H113.701C113.91 1 113.979 1.2783 113.796 1.37639L103.273 7"
              stroke="white"
              stroke-linecap="round"
            ></path>
          </svg>
        </div>
      </button>
    </>
  );
};

export default ErrorNotification;
