import { User } from "@auth0/auth0-spa-js";
import {
  getChangeUserTypeInput,
  getStartTradeInput,
  getStopTradeInput,
} from "../../helper/api/accounts.helper";
import { customAxios, requestHandler } from "../axios.service";
import { IAccountsFunction } from "../../types/types";
import * as schema from "../../types/response/accounts.type";
import axios, { AxiosInstance } from "axios";
import baseUrl from "../../shared/baseUrl";
import { getUserType } from "../../helper/userType.helper";
import { getUserId } from "../../helper/userId.helper";
import { deletePortfolio, disableNotification } from "./notification.service";

export class TDAccountsFunction implements IAccountsFunction {
  customAxios: AxiosInstance;

  constructor() {
    this.customAxios = axios.create({
      baseURL: baseUrl.api,
    });
    this.customAxios.interceptors.request.use(requestHandler);
  }

  authorize = async (user: User) => {
    const userId = await getUserId(user);
    const { data } = await this.customAxios.get(
      `td/authenticate/user/${userId}`
    );

    let d = schema.AuthorizeAmeritradeSchema.parse(data);
    return d;
  };

  getBrokerageAccounts = async (user: User) => {
    const userId = await getUserId(user);

    const { data } = await this.customAxios.get(`td/retrieve/user/${userId}`);
    let d = schema.getBrokerageAccountsSchema.parse(data);
    return d;
  };

  checkSubscriptionStatus = async (accountId: string) => {
    const { data } = await this.customAxios.get(
      `td/socket/status/${accountId}`
    );
    let d = schema.subscriptionStatusSchema.parse(data);

    return d;
  };

  startTrade = async (accountId: string) => {
    const formData = getStartTradeInput(accountId, getUserType());
    await this.customAxios.post("td/trade/start", formData);
  };

  stopTrade = async (accountId: string) => {
    const formData = getStopTradeInput(accountId, getUserType());
    await this.customAxios.post("td/trade/stop", formData);
  };

  unauthorize = async (accountId: string) => {
    await disableNotification(accountId);
    await deletePortfolio(accountId);
    await this.customAxios.delete(`td/revoke/account/${accountId}`);
  };
}

export const changeUserType = async (user: User) => {
  const formData = getChangeUserTypeInput(getUserType(), user);
  await customAxios.api.post("user/update/type", formData);
};
