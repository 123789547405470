import { User } from "@auth0/auth0-spa-js";
import {
  getUserIfAlreadyExistInput,
  getUserRegisterInput,
} from "../../helper/api/user.helper";
import { customAxios } from "../axios.service";
import * as schema from "../../types/response/user.type";

// 1
export const registerUserToBackend = async (user: User) => {
  console.log("Registering User to the Database...");

  const formData = getUserRegisterInput(user);
  const { data } = await customAxios.api.post("user/register", formData);

  console.log("Successfully Registerd the new User!");

  return data;
};

// 2
export const getUserIfAlreadyExist = async (user: User) => {
  console.log("Fetching User if Already Exists...");

  const formData = getUserIfAlreadyExistInput(user);
  const { data } = await customAxios.api.post("/user/retrieve", formData);

  let d = schema.userAlreadyExistSchema.parse(data);

  console.log("User Already Found!");

  return d;
};
