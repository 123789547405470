import React, { useState, useMemo, useEffect } from "react";
import "./AuthBrokerage.css";
import { useAuth, useBrokerage, useHost } from "../../../../store/store";
import { logError } from "../../../../helper/logError.helper";
import { IBAccountsFunction } from "../../../../services/api/accounts.ib.service";
import { User, useAuth0 } from "@auth0/auth0-react";
import { AuthStatus, HostStatus } from "../../../../types/types";

const svgIcon = [
  <svg
    width="9"
    height="17"
    viewBox="0 0 9 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.25 16L0.75 8.5" stroke="black" stroke-linecap="round" />
    <path d="M8.25 1L0.75 8.5" stroke="black" stroke-linecap="round" />
  </svg>,
  <svg
    width="115"
    height="8"
    viewBox="0 0 115 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1H114.201C114.41 1 114.479 1.2783 114.296 1.37639L103.773 7"
      stroke="white"
      stroke-linecap="round"
    ></path>
  </svg>,
];

type AuthInput = {
  username: string;
  password: string;
};

const periodicDelay = 30 * 1000; // 30 sec

const AuthBrokerage = () => {
  const { user } = useAuth0();
  const [, setBrokerageState] = useBrokerage();
  const [sHost, setHostState] = useHost();
  const [, setAuthState] = useAuth();
  const [authInput, setAuthInput] = useState<AuthInput>({
    username: "",
    password: "",
  });

  const IB = useMemo(() => new IBAccountsFunction(), []);

  useEffect(() => {
    const fetchHostStatus = async (user: User) => {
      try {
        console.log("Checking Host Status...");
        const { authStatus } = await IB.checkAuthStatus(user);
        switch (authStatus) {
          case AuthStatus.SUCCESS:
            setBrokerageState(true);
            setHostState(HostStatus.READY);
            break;
          case AuthStatus.FAILURE:
            setHostState(HostStatus.UNAVAILABLE);
            break;
          default:
            break;
        }
      } catch (err) {
        logError(err);
      }
    };

    let i: number;
    if (sHost === HostStatus.PROVISIONING) {
      i = setInterval(fetchHostStatus, periodicDelay);
    }

    return () => {
      clearInterval(i);
    };
  }, [IB, sHost, setBrokerageState, setHostState]);

  const handleConfigOnClick = async () => {
    try {
      if (!user) return;
      // call api to configure
      await IB.authorize(user, authInput.username, authInput.password);
      setHostState(HostStatus.PROVISIONING);
    } catch (err) {
      logError(err);
    }
  };

  const handleBackButtonOnClick = () => {
    setAuthState(false);
  };
  return sHost === HostStatus.PROVISIONING ? (
    <div className="preload--wait">
      Setting up <b>Interactive Broker</b> for you. Please wait, it may take a
      while <div className="dot-spin"></div>
    </div>
  ) : (
    <div className="authenticaiton-container">
      <div className="auth-head-container">
        <div className="back-icon" onClick={handleBackButtonOnClick}>
          {svgIcon[0]}
        </div>
        <div className="auth-head--content">
          <div>
            <img src="../imgs/g9910.svg" alt="Interactive Brokers" />
          </div>
          <div>Sign in to with your Interactive Brokers Account</div>
        </div>
      </div>
      <div className="input__boxes">
        <div className="username--input">
          <input
            type="text"
            placeholder="Username"
            value={authInput.username}
            onChange={(e) =>
              setAuthInput({ ...authInput, username: e.target.value })
            }
          />
        </div>
        <div className="pass-input">
          <input
            type="password"
            placeholder="Password"
            value={authInput.password}
            onChange={(e) =>
              setAuthInput({ ...authInput, password: e.target.value })
            }
          />
        </div>
      </div>
      <button className="btn__sky" onClick={handleConfigOnClick}>
        <div>Configure</div>
        <div>{svgIcon[1]}</div>
      </button>
    </div>
  );
};

export default AuthBrokerage;
