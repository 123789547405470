import { User } from "@auth0/auth0-spa-js";
import getNameFromUser from "../getNameFromUser.helper";

export const getUserRegisterInput = (user: User) => {
  const { email, family_name } = user;

  const userRegisterData = {
    Email: email,
    Password: "test",
    FirstName: getNameFromUser(user),
    LastName: family_name ? family_name : "LAST_NAME",
    UserType: "CUSTOMER",
  };

  return userRegisterData;
};

export const getUserIfAlreadyExistInput = (user: User) => {
  return { email: user.email };
};
