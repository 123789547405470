import React, { useEffect, useState } from "react";
import styles from "./Invoice.module.css";
import { getInvoiceHistory } from "../../../../services/api/bills.service";
import { logError } from "../../../../helper/logError.helper";
import { Invoice } from "../../../../types/types";
import InvoiceRow from "./InvoiceRow";

const InvoiceTable = () => {
  const [invoices, setInvoices] = useState<Invoice[] | null>();
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number | null>();

  const invoicePerPage = 3;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getInvoiceHistory();
        setTotalPages(Math.floor(data.length / invoicePerPage) + 1);
        setInvoices(data);
      } catch (err) {
        logError(err);
      }
    };
    fetchData();
  }, []);

  const paginator = (totalNumOfPages: number) => {
    const pageArray = new Array(totalNumOfPages).fill(null);
    return pageArray.map((_, idx) => (
      <div
        key={idx}
        onClick={() => setcurrentPage(idx + 1)}
        className={`${styles["pages"]} ${
          idx + 1 === currentPage ? styles["current-page"] : ""
        }`}
      >
        {idx + 1}
      </div>
    ));
  };

  const isInvoiceInCurrentPage = (invoiceIdx: number) => {
    invoiceIdx++;
    if (invoiceIdx <= (currentPage - 1) * invoicePerPage) return false;
    if (invoiceIdx > currentPage * invoicePerPage) return false;
    return true;
  };

  return (
    <>
      <div className={styles["actual-box-wrapper"]}>
        <div className={styles["table-header"]}>
          <div
            className={`${styles["head-checkbox"]} ${styles["invoice-number"]}`}
          >
            {/* <input id="checky-box" type="checkbox" /> */}
            <label htmlFor="checky-box">Invoice</label>
          </div>
          <div className={styles["billing-date"]}>Billing Date</div>
          <div className={styles["billing-status"]}>Status</div>
          <div className={styles["amount"]}>Amount</div>
          <div className={styles["subs-plaan"]}>Plan</div>
          <div className={styles["subs-inv-download"]} style={{ opacity: 0 }}>
            Download
          </div>
        </div>
        {invoices &&
          invoices.map((invoice, rowId) => {
            if (!isInvoiceInCurrentPage(rowId)) return <></>;
            else return <InvoiceRow key={rowId} invoiceDetails={invoice} />;
          })}
      </div>
      {invoices && invoices.length > 0 && totalPages && totalPages > 1 && (
        <div className={styles["pagination-nav"]}>{paginator(totalPages)}</div>
      )}
    </>
  );
};

export default InvoiceTable;
