import React, { useMemo, useState } from "react";
import "./SetupBrokerage.css";
import {
  useAuth,
  useBilling,
  useBroker,
  useBrokerage,
} from "../../../../store/store";
import { TDAccountsFunction } from "../../../../services/api/accounts.td.service";
import { logError } from "../../../../helper/logError.helper";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";
import { BrokerType } from "../../../../types/types";

const svgIcon = (
  <svg
    width="115"
    height="8"
    viewBox="0 0 115 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 1H113.701C113.91 1 113.979 1.2783 113.796 1.37639L103.273 7"
      stroke="white"
      stroke-linecap="round"
    />
  </svg>
);

function SetupBrokerage() {
  const [query, setQuery] = useSearchParams();
  const [selected, setSelected] = useState<0 | 1>(1);
  const [, setAuthState] = useAuth();
  const [, setBroker] = useBroker();
  const [, setBrokerageState] = useBrokerage();
  const { user } = useAuth0();
  const [sBilling] = useBilling();

  const TD = useMemo(() => new TDAccountsFunction(), []);

  const handleSelection = (id: 0 | 1) => {
    setSelected(id);
  };

  const configureAmeritrade = async () => {
    try {
      if (!user) return;
      //configure Ameritrade
      const { Url } = await TD.authorize(user);
      window.location.href = Url;
      // testing
      // setBrokerageState(true); // not setting brokerage as user will be redirected
    } catch (err) {
      logError(err);
    }
  };

  const configureInteractiveBroker = async () => {
    // get to IBKR status page
    localStorage.setItem("download_requested", "true"); // set to true to use during refresh before the user actually configures to ibkr
    setBroker(BrokerType.INTERACTIVE);
    setBrokerageState(true);
  };

  const configureButtonOnClick = () => {
    selected ? configureInteractiveBroker() : configureAmeritrade();
  };

  return (
    <div>
      <h4>Set up your Brokerage Account</h4>
      <p>Which brokerage platform do you use?</p>
      <div className="broker-container">
        {/* <div
          className={`cardHovering tdAmeritrade ${
            !sBilling ? "remove-hover" : ""
          }`}
          id={selected === 0 && sBilling ? "active-radio" : ""}
          onClick={() => handleSelection(0)}
        >
          <button className="sphere">
            <div className="round-ext"></div>
            <div className="round-int"></div>
          </button>
          <div className="broker-content">
            Setup <b>TD Ameritrade</b>
          </div>
        </div> */}
        <div
          className="cardHovering interactive"
          id={selected === 1 ? "active-radio" : ""}
          onClick={() => handleSelection(1)}
        >
          <button className="sphere">
            <div className="round-ext"></div>
            <div className="round-int"></div>
          </button>
          <div className="broker-content">
            Setup <b>Interactive Brokers</b>
          </div>
        </div>
      </div>
      <button
        disabled={!sBilling}
        className={`btn__sky ${!sBilling ? "disabled-btn" : ""}`}
        onClick={configureButtonOnClick}
      >
        <div>Next</div>
        <div>{svgIcon}</div>
      </button>
      <p className="bottom-disclaimer">
        *Additional brokerages, such as Interactive Brokers, WeBull, Fideltiy,
        E*TRADE, Robinhood, Interactive Brokers, etc. will be available soon.
        Please contact support@tradezyapp.com.
      </p>
    </div>
  );
}

export default SetupBrokerage;
