import React from "react";
import styles from "./Policy.module.css";

const Privacy = () => {
  return (
    <div className={`${styles["container"]} ${styles["my-resets"]}`}>
      <h2 className={styles["policy-container"]}>Privacy Policy</h2>
      <p className={styles["same-para"]}>
        If you use our trade-alert and order-preparation service in any way
        (collectively, “Services”), [Influencer] ("[Influencer]," "we," "us,"
        and "our") will ask for, collect, and store personal information about
        you (your “Personal Data”). This document (“Privacy Policy”) describes:
      </p>
      <ul>
        <li>the types of personal information we collect,</li>
        <li>how we use the information, </li>
        <li>with whom we may share it, and</li>
        <li>
          the choices available to you regarding our use of the information.
        </li>
      </ul>
      <p className={styles["same-para"]}>
        We also describe measures we take to protect the security of the
        information and how you can contact us about our privacy practices.
      </p>
      <p className={styles["same-para"]}>
        If you do not want us to collect Personal Data from you or share
        Personal Data with any third parties or otherwise disclose your Personal
        Data, you should not use the Services.
      </p>
      <ol>
        <h3>
          <li>How We Protect Personal Information</li>
        </h3>
        <div style={{ marginTop: "-10px" }}>
          To protect your personal information from unauthorized access and use,
          we use security measures that comply with federal law. These measures
          include computer safeguards and secured files and buildings. We
          restrict our employees’ access to your information so that it is only
          accessible when they need it to do their work, and we require
          companies that provide services for us to protect your information. We
          also have safeguards designed to protect the personal information you
          provide from unauthorized access, destruction, loss, alteration,
          disclosure or use.
        </div>
        <h3>
          <li>Information We Obtain</li>
        </h3>
        <div style={{ marginTop: "-10px" }}>
          We obtain personal information about you in various ways, such as when
          you use our Services, communicate with us, or interact with any of our
          representatives. The types of personal information we may obtain about
          you may include:
          <ul>
            <li>
              Identifiers such as name, postal and email address, and phone
              number;
            </li>
            <li>Login credentials for your [Influencer] account;</li>
            <li>Credentials for your linked brokerage account;</li>
            <li>
              Payment information via Stripe or Paypal, or other payment
              providers;
            </li>
            <li>Data on how you use the Services;</li>
            <li>
              Trade information such as cost basis, security purchased, price,
              date and time, and so on;
            </li>
            <li>
              Information you provide through member services interactions and
              that you provide about your experience with [Influencer],
              including via questionnaires, surveys, participation in user
              research or other feedback; and
            </li>
            <li>
              Other information you choose to provide, such as through emails or
              other communications (such as with our representatives),
              referrals, on social media pages, or in registrations and sign-up
              forms.
            </li>
          </ul>
        </div>
        <h3>
          <li>How We Use the Information We Obtain</li>
        </h3>
        <div style={{ marginTop: "-10px" }}>
          We may use the personal information we obtain for purposes such as the
          following:
          <ul>
            <li>Provide the Services;</li>
            <li>Process and fulfill transactions;</li>
            <li>Establish and manage your account;</li>
            <li>Personalize your experience on our Services;</li>
            <li>
              Respond to inquiries, provide member support and resolve disputes;
            </li>
            <li>
              Advertise and market our products and services, and send
              information about third-party products and services we think may
              interest you;
            </li>
            <li>
              Provide member support and quality assurance, and conduct customer
              service training;
            </li>
            <li>
              Collect fees and other amounts owed in connection with your
              [Influencer] account;
            </li>
            <li>Operate, evaluate and improve our business;</li>
            <li>
              Protect against, identify and prevent fraud and other criminal
              activity, claims and other liabilities;
            </li>
            <li>
              Exercise our rights and remedies and defend against legal claims;
              and
            </li>
            <li>
              Comply with and enforce applicable legal requirements, relevant
              industry standards and [Influencer] policies.
            </li>
          </ul>
          We also may use the information in other ways for which we provide
          specific notice at the time of collection. Any opt-ins that you for
          messaging purposes, will not be shared with any third-party
          organizations.
        </div>
        <h3>
          <li>Information We Share</li>
        </h3>
        <div style={{ marginTop: "-10px" }}>
          <p className={styles["same-para"]}>
            We may share the information we obtain about you with our affiliates
            and subsidiaries and our business partners. We may share your
            information for marketing purposes; if you wish to limit such
            sharing, see Section 7.
          </p>
          <p className={styles["same-para"]}>
            We also may share the information we obtain about you with
            third-party vendors and other entities we engage to perform services
            on our behalf, such as payment processors, risk detection and
            mitigation tools, and modeling and analytics tools. This information
            is shared to facilitate our everyday operations and not for
            marketing purposes, and you may not opt out of it.
          </p>
          <p className={styles["same-para"]}>
            We also may share your Personal Data:
            <ul>
              <li>
                to comply, as necessary, with applicable laws and regulatory
                requirements;
              </li>
              <li>for legal process;</li>
              <li>
                to respond to mandatory legal or governmental requests or
                demands for information;
              </li>
              <li>
                to enforce our agreements, policies, procedures, and terms of
                use; and
              </li>
              <li>
                to protect ourselves, our customers, or the general public from
                illegal activities.
              </li>
            </ul>
            We reserve the right to transfer any personal information we have
            about you to a successor company if we sell or transfer all or a
            portion of our business or assets (including in the event of a
            merger, acquisition, joint venture, reorganization, divestiture,
            dissolution or liquidation).
          </p>
        </div>
        <h3>
          <li>Third-Party Analytics Services</li>
        </h3>
        <div>We may use third-party analytics on our Platform.</div>
        <h3>
          <li>Interest-Based Advertising</li>
        </h3>
        <div style={{ marginTop: "-10px" }}>
          <p className={styles["same-para"]}>
            We may allow others to provide analytics services and serve
            advertisements on our behalf across the Internet and in
            applications. These entities may use cookies, web beacons, device
            identifiers and other technologies to collect information about your
            use of the Services and other websites and applications, including
            your IP address, web browser, mobile network information, pages
            viewed, time spent on pages or in apps, links clicked and conversion
            information.
          </p>
          <p className={styles["same-para"]}>
            The Services are not designed to respond to “do not track” signals
            from browsers. To learn how to opt out of interest-based advertising
            in the U.S., please visit{" "}
            <a href="www.aboutads.info/choices" rel="no-referrer">
              www.aboutads.info/choices
            </a>
            .
          </p>
        </div>
        <h3>
          <li>Your Rights & Choices</li>
        </h3>
        <div style={{ marginTop: "-10px" }}>
          <p className={styles["same-para"]}>
            You may exercise certain choices in connection with the personal
            information we collect from you. Federal law lets you limit our
            sharing of data for marketing purposes, but not for everyday
            business purposes. For example, you can direct us not to share your
            personal information with business partners so they can market to
            you, but you can't stop other kinds of sharing that are necessary
            for us to provide you with our services.
          </p>
          <p className={styles["same-para"]}>
            To update your preferences, limit the communications you receive
            from us, or submit a request, please contact us as indicated in the
            "How to Contact [Influencer]" section of this Privacy Policy. You
            can also unsubscribe from our marketing mailing lists by following
            the "Unsubscribe" link in our emails.
          </p>
          <p className={styles["same-para"]}>
            If you are a [Influencer] user, you may update, correct, or delete
            some of your account information by emailing us at{" "}
            <a href="mailto:support@tradezyapp.com" rel="no-referrer">
              support@tradezyapp.com
            </a>
            .
          </p>
          <p className={styles["same-para"]}>
            With your consent, we may send promotional and non-promotional push
            notifications or alerts to your mobile device. You can deactivate
            these messages at any time by emailing us at{" "}
            <a href="mailto:support@tradezyapp.com" rel="no-referrer">
              support@tradezyapp.com
            </a>
            .
          </p>
        </div>
        <h3>
          <li>Children's Privacy</li>
        </h3>
        <div style={{ marginTop: "-10px" }}>
          Our Services are designed for a general audience and are not directed
          to children. We do not knowingly collect, use, sell, or share any
          Personal Data from anyone under the age of 18. If we become aware that
          a child under the age 18 has provided Personal Data to us, we will
          delete it.
        </div>
        <h3>
          <li>Links to Third-Party Services and Features</li>
        </h3>
        <div style={{ marginTop: "-10px" }}>
          For your convenience and information, our Services may provide links
          to other online services, and may include third-party features such as
          apps, tools, widgets, and plug-ins. These online services and
          third-party features may operate independently from us. The privacy
          practices of the relevant third parties, including details on the
          information they may collect about you, are subject to the privacy
          statements of these parties, which we strongly suggest you review. To
          the extent any linked online services or third-party features are not
          owned or controlled by [Influencer], we are not responsible for these
          third parties' information practices.
        </div>
        <h3>
          <li>Updates to this Privacy Policy</li>
        </h3>
        <div style={{ marginTop: "-10px" }}>
          We may update this Privacy Policy from time to time and without prior
          notice to you to reflect changes in our personal information
          practices. We will indicate at the top of the policy when it was most
          recently updated.
        </div>
        <h3>
          <li>How to Contact [Influencer]</li>
        </h3>
        <div style={{ marginTop: "-10px" }}>
          You can update your preferences, ask us to remove your information
          from our mailing lists, submit a request, or ask us questions about
          this Privacy Policy by writing to us at{" "}
          <a href="mailto:support@tradezyapp.com" rel="no-referrer">
            support@tradezyapp.com
          </a>
          .
        </div>
        <h3>
          <li>California Consumer Privacy Rights</li>
        </h3>
        <div style={{ marginTop: "-10px" }}>
          As a California resident and consumer, you have certain choices
          regarding your personal information under the California Consumer
          Privacy Act (CCPA), as described below:
        </div>
        <span>
          <ul>
            <li>
              <b>Right to Access: </b>
              You may request, up to twice in a 12-month period, information
              about the Personal Data we have collected, used, disclosed or sold
              about you during the past 12 months, including:
              <ul>
                <li>
                  the categories and specific pieces of Personal Data we have
                  collected about you;
                </li>
                <li>
                  the categories of sources from which we collected the Personal
                  Data;
                </li>
                <li>
                  the business or commercial purpose for which we collected the
                  Personal Data;
                </li>
                <li>
                  the categories of third parties with whom we shared the
                  Personal Data; and
                </li>
                <li>
                  the categories of Personal Data about you that we disclosed
                  for a business purpose and sold to third parties, and the
                  categories of third parties to whom the information was
                  disclosed or sold.
                </li>
              </ul>
            </li>
            <li>
              <b>Right to Delete: </b> You may request that we delete the
              Personal Data we have collected from you, subject to certain
              limitations under the CCPA.
            </li>
            <li>
              <b>Right to opt-out from sale of Personal Data: </b>
              You have the right to opt-out of the sale of your Personal Data.
              [Influencer] does not sell your Personal Data.
            </li>
            <li>
              <b>Right to non-discrimination: </b>
              If you choose to exercise any of your rights under the CCPA, we
              may not discriminate against you. To the extent permitted by
              applicable law, we may charge a reasonable fee to comply with your
              request.
            </li>
          </ul>
        </span>
        <div style={{ marginTop: "10px" }}>
          To submit a request to exercise any of the rights described above, you
          may email us at support@tradezyapp.com. We will verify your identity
          before responding to your request by either verifying that the email
          address from which you send the request matches your email address
          that we have on file, or by requiring you to log into your account.
        </div>
        <h3>Consumer Request by an Authorized Agent</h3>
        <div style={{ marginTop: "-10px" }}>
          If any authorized agent submits a consumer request under the CCPA on
          your behalf, we require the authorized agent to submit the following
          information so that we can confirm their authority to act on your
          behalf:
        </div>
        <ul>
          <li>
            Evidence of authorization to act on behalf of the California
            consumer: (1) California Secretary of State authorization, (2)
            notarized written permission from the California consumer, or (3)
            power of attorney.
          </li>
          <li>
            Evidence of identity of the California consumer: (1) first and last
            name, (2) email address, and (3) password.
          </li>
        </ul>
        <p className={styles["same-para"]}>
          NOTICE TO CALIFORNIA RESIDENTS - YOUR CALIFORNIA PRIVACY RIGHTS (AS
          PROVIDED BY CALIFORNIA CIVIL CODE SECTION 1798.83):
        </p>
        <p className={styles["same-para"]}>
          A CALIFORNIA RESIDENT WHO HAS PROVIDED PERSONAL DATA TO A BUSINESS
          WITH WHOM HE/SHE HAS ESTABLISHED A BUSINESS RELATIONSHIP FOR PERSONAL,
          FAMILY, OR HOUSEHOLD PURPOSES (A “CALIFORNIA CUSTOMER”) MAY REQUEST
          INFORMATION ABOUT WHETHER THE BUSINESS HAS DISCLOSED PERSONAL DATA TO
          ANY THIRD PARTIES FOR THE THIRD PARTIES' DIRECT MARKETING PURPOSES. IN
          GENERAL, IF THE BUSINESS HAS MADE SUCH A DISCLOSURE OF PERSONAL DATA,
          UPON RECEIPT OF A REQUEST BY A CALIFORNIA CUSTOMER, THE BUSINESS IS
          REQUIRED TO PROVIDE A LIST OF ALL THIRD PARTIES TO WHOM PERSONAL DATA
          WAS DISCLOSED IN THE PRECEDING CALENDAR YEAR, AS WELL AS A LIST OF THE
          CATEGORIES OF PERSONAL DATA THAT WERE DISCLOSED. CALIFORNIA CUSTOMERS
          MAY REQUEST FURTHER INFORMATION ABOUT OUR COMPLIANCE WITH THIS LAW BY
          E-MAILING{" "}
          <a href="mailto:support@tradezyapp.com" rel="no-referrer">
            support@tradezyapp.com
          </a>
          . PLEASE NOTE THAT WE ARE REQUIRED TO RESPOND TO ONE REQUEST PER
          CALIFORNIA CUSTOMER EACH YEAR AND WE ARE NOT REQUIRED TO RESPOND TO
          REQUESTS MADE BY MEANS OTHER THAN THROUGH THIS E-MAIL ADDRESS.
        </p>
      </ol>
    </div>
  );
};

export default Privacy;
