import React from "react";
import styles from "./ConfiguredBilling.module.css";
import { logError } from "../../../helper/logError.helper";
import { deleteSubscription } from "../../../services/api/bills.service";
import { useBillingDetails } from "../../../store/store";
import { ReactComponent as BillingLogo } from "./../../../assets/billing.svg";
import InvoiceTable from "./Invoice/InvoiceTable";
import { useAuth0 } from "@auth0/auth0-react";
import { getDateFormatted } from "../../../helper/getDateFormatted.helper";

const ConfiguredBilling = () => {
  const { user } = useAuth0();
  const [subscriptionMetadata] = useBillingDetails();

  const capitalizeFirstLetter = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const getPlanRenewalDetails = () => {
    if (!subscriptionMetadata) return;

    const planCurrentPeriodEnd = getDateFormatted(
      subscriptionMetadata.current_period_end
    );

    if (subscriptionMetadata.cancel_at_period_end) {
      return `Plan Expiring on ${planCurrentPeriodEnd}`;
    } else {
      return `Plan will Renew on ${planCurrentPeriodEnd}`;
    }
  };

  const handleCancelSubscriptionOnClick = async () => {
    if (subscriptionMetadata?.cancel_at_period_end) return;
    try {
      await deleteSubscription();
      window.location.reload();
    } catch (err) {
      logError(err);
    }
  };

  return subscriptionMetadata ? (
    <>
      <div>
        <div className={styles["box-header-tag__"]}>Your Billing Details</div>
        <div className={styles["box-header-tag__1"]}>
          Manage your billing and payment details
        </div>
      </div>
      {/* <div className={styles["box-container"]}>
      <div>
        <p className={styles["amt"]}>
          $ {subscriptionMetadata.amount} per{" "}
          {capitalizeFirstLetter(subscriptionMetadata.interval)}
        </p>
        <p className={styles["subsc"]}>
          <span>Subscription: </span>
          <span>
            {capitalizeFirstLetter(subscriptionMetadata.interval) + "ly"}
          </span>
        </p>
      </div>
      <div className={styles["plan-controller"]}>
        {!subscriptionMetadata.cancel_at_period_end ? (
          <button
            className={styles["subs-button__"]}
            onClick={handleCancelSubscriptionOnClick}
          >
            Cancel Subscription
          </button>
        ) : (
          <div style={{ opacity: "0" }}>Hidden</div>
        )}
        <div
          className={
            subscriptionMetadata.cancel_at_period_end
              ? styles["red-alert"]
              : styles["green-alert"]
          }
        >
          {getPlanRenewalDetails()}
        </div>
      </div>
    </div> */}
      <div className={styles["main-billing-container"]}>
        <div className={styles["bill-info-container"]}>
          <div className={styles["billing-info"]}>
            <div className={styles["billing-info-_1__"]}>
              <div>
                ${subscriptionMetadata.amount} per{" "}
                {capitalizeFirstLetter(subscriptionMetadata.interval)}
              </div>
              <div>
                <span>Subscription: </span>
                <span>
                  {capitalizeFirstLetter(subscriptionMetadata.interval) + "ly"}
                </span>
              </div>
            </div>
            <div className={styles["billing-info--__--1-2"]}>
              <div>
                {!subscriptionMetadata.cancel_at_period_end ? (
                  <button
                    className={styles["cancel-plan-btn"]}
                    onClick={handleCancelSubscriptionOnClick}
                  >
                    Cancel Plan
                  </button>
                ) : (
                  <div style={{ opacity: "0" }}>Hidden</div>
                )}
              </div>
              <div
                className={
                  subscriptionMetadata.cancel_at_period_end
                    ? styles["red-alert"]
                    : styles["green-alert"]
                }
              >
                {getPlanRenewalDetails()}
              </div>
            </div>
          </div>
          <div className={styles["billing-card-info"]}>
            <div className={styles["bills-_i_nfo"]}>
              <div>
                <BillingLogo />
              </div>
              <div className={styles["card-details--poitn"]}>
                <div className={styles["card-num"]}>
                  XXXXX
                  {subscriptionMetadata.last4}
                </div>
                <div className={styles["card-mail"]}>{user && user.email}</div>
              </div>
            </div>
            <div className={styles["edit-card-details"]}>
              <button onClick={() => false}>Edit</button>
            </div>
          </div>
        </div>
        <div className={styles["billing-centricit"]}>
          <div className={styles["billing-centricit-head"]}>
            Your Invoice Details
          </div>
          <div className={styles["billing-centricit-content"]}>
            Pick an account plan that fits your workflow
          </div>
        </div>
      </div>
      <div className={styles["table-wrapper"]}>
        <InvoiceTable />
      </div>
    </>
  ) : (
    <></>
  );
};

export default ConfiguredBilling;
