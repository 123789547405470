import { IBAccountsFunction } from "../services/api/accounts.ib.service";
import { TDAccountsFunction } from "../services/api/accounts.td.service";
import { BrokerType, IAccountsFunction } from "../types/types";

const TD = new TDAccountsFunction();
const IB = new IBAccountsFunction();

export const getAccountsFunction = (broker: BrokerType): IAccountsFunction => {
  switch (broker) {
    case BrokerType.AMERITRADE:
      return TD;
    case BrokerType.INTERACTIVE:
      return IB;
  }
};
