import React from "react";
import "./UnavailCards.css";
import { BrokerageAccount } from "../../../../../types/types";

type UnavailCardsProp = {
  brokerageAccount: BrokerageAccount;
};

function UnavailCards({ brokerageAccount }: UnavailCardsProp) {
  return (
    <div className="unavailable__cards">
      <div className="unvail-_card__wrapper">
        <div className="info-_container__box_">
          <div className="rounded-_box_radio">
            <div className="radio__sphere"></div>
          </div>
          <div className="account-_info">
            <div className="text-_style">
              {brokerageAccount.BrokerageAccountId}
            </div>
            <div className="type-_text__">Account ID</div>
          </div>
          <div className="acct_-type">
            <div className="text-_style">
              {brokerageAccount.AccountType.charAt(0).toUpperCase() +
                brokerageAccount.AccountType.slice(1).toLowerCase()}
            </div>
            <div className="type-_text__">Account Type</div>
          </div>
        </div>
        <div className="balance-_sheet">
          <div>$ {brokerageAccount.AccountValue}</div>
          <div className="type-_text__">Account Value</div>
        </div>
      </div>
      <div className="unvailable_card-tag">Not Available</div>
    </div>
  );
}

export default UnavailCards;
