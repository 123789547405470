import React from "react";
import styles from "../Dashboard.module.css";
import { useBilling, useNotification } from "../../../store/store";
import { useNavigate } from "react-router-dom";

const NotificationDashboard = () => {
  const [sBilling] = useBilling();
  const [sNotification] = useNotification();
  const navigate = useNavigate();

  const getStatusText = (status: boolean, text: string) => {
    return !status ? (sBilling ? "Not " + text : "Complete Billing") : text;
  };

  return (
    <div
      className={`${styles["configure-tab"]} ${
        sNotification ? styles["outline-success"] : styles["outline-fail"]
      }`}
    >
      <h2 className={styles["box-header"]}>Notification Configuration</h2>
      <div className={styles["configured-info"]}>
        <div className={styles["configure-metadata-1"]}>
          <div className={styles["configure-status"]}>
            <div className={styles["configure-props"]}>
              {getStatusText(sNotification, "Configured")}
            </div>
            <div
              className={`${styles["configure-dot"]} ${
                sNotification ? styles["green-dot"] : styles["red-dot"]
              }`}
            ></div>
          </div>
          <div className={styles["configure-value"]}>Status</div>
        </div>
        <div className={styles["configure-metadata-2"]}>
          <div className={styles["configure-props"]}>
            {sNotification ? "WhatsApp" : "N/A"}
          </div>
          <div className={styles["configure-value"]}>Channel</div>
        </div>
        <div className={styles["configure-metadata-3"]}>
          {sBilling && (
            <button
              onClick={() => navigate("/accounts/notification")}
              className={styles["action-btn"]}
            >
              Go to Notification
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationDashboard;
