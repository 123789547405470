import React, { useState } from "react";
import "./Navbar.css";
import Menu from "./MenuItems/Menu";
import UserAuth from "./MenuItems/UserAuth";
import { useNavigate } from "react-router-dom";
import { ReactComponent as HamsIcon } from "./../../assets/charm_menu-hamburger-2.svg";
import { ReactComponent as CrossIcon } from "./../../assets/eva_close-outline.svg";

function Navbar() {
  const navigate = useNavigate();
  const [mobileView, setMobileView] = useState<true | false>(false);

  const handleLogoOnClick = () => {
    navigate("/");
  };

  const handleMenuNavigation = () => {
    setMobileView(false);
  };

  return (
    <>
      <nav className="main-header">
        <div className="main-header-tabs__">
          <div className="logo" onClick={handleLogoOnClick}>
            <img
              style={{ width: "175px", marginTop: "10px" }}
              src="./../imgs/Navbar/logo_lupton.svg"
              alt="logo"
            />
          </div>
          <Menu clickHandler={handleMenuNavigation} />
          <UserAuth />
        </div>
        <div className="hams-wrapper">
          <div className="logo" onClick={handleLogoOnClick}>
            <img
              style={{ width: "175px", marginTop: "10px" }}
              src="./../imgs/Navbar/logo_lupton.svg"
              alt="logo"
            />
          </div>
          <HamsIcon
            onClick={() => setMobileView(true)}
            className="hamburger-icon"
          />
        </div>
      </nav>
      <div
        className={`mobile-view-nav-bar-hidden ${mobileView ? "display-class" : ""
          }`}
      >
        <div className="mobile-nav-header-bar">
          <div className="logo" onClick={handleLogoOnClick}>
            <img
              style={{ width: "175px", marginTop: "10px" }}
              src="./../imgs/Navbar/logo_lupton.svg"
              alt="logo"
            />
          </div>
          <div onClick={() => setMobileView(false)}>
            <CrossIcon className="cross-icon__2" />
          </div>
        </div>
        <div className="mobile-header-menu__">
          <Menu clickHandler={handleMenuNavigation} />
          <div className="break--line__2"></div>
          <UserAuth />
        </div>
      </div>
    </>
  );
}

export default Navbar;
