import { z } from "zod";
import { UserType } from "../types";

export const registerUserSchema = z.object({});

export const userAlreadyExistSchema = z.object({
  authenticated: z.boolean(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  userId: z.string(),
  userType: z.custom<UserType>(),
});
