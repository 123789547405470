import React, { useState, useEffect } from "react";
import styles from "./Popup.module.css";
import { getSubscriptionStatus } from "../../services/api/bills.service";
import { logError } from "../../helper/logError.helper";
import { useBilling } from "../../store/store";

const svgIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <rect width="64" height="64" rx="32" fill="#5652E9" />
    <path
      d="M26.2402 45.5449C25.89 45.5453 25.5431 45.4765 25.2196 45.3424C24.8961 45.2083 24.6022 45.0116 24.3549 44.7636L14.1149 34.5236C13.8602 34.2776 13.657 33.9834 13.5173 33.658C13.3775 33.3327 13.304 32.9828 13.3009 32.6287C13.2978 32.2746 13.3653 31.9235 13.4994 31.5957C13.6334 31.268 13.8314 30.9703 14.0818 30.7199C14.3322 30.4695 14.6299 30.2715 14.9577 30.1374C15.2854 30.0033 15.6365 29.9359 15.9906 29.9389C16.3447 29.942 16.6946 30.0156 17.02 30.1553C17.3453 30.2951 17.6396 30.4982 17.8855 30.7529L26.2402 39.1076L46.1149 19.2329C46.6178 18.7472 47.2914 18.4784 47.9906 18.4845C48.6898 18.4906 49.3586 18.771 49.8531 19.2654C50.3475 19.7598 50.6279 20.4287 50.634 21.1279C50.6401 21.8271 50.3713 22.5007 49.8856 23.0036L28.1255 44.7636C27.8782 45.0116 27.5844 45.2083 27.2608 45.3424C26.9373 45.4765 26.5904 45.5453 26.2402 45.5449Z"
      fill="white"
    />
  </svg>
);

const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
      fill="#CCCCCC"
    />
  </svg>
);

type EmailVerifiedPopupProps = {
  closePopup: () => void;
};

const EmailVerifiedPopup = (props: EmailVerifiedPopupProps) => {
  const [popupActive, setPopupActive] = useState(true);

  return (
    <>
      <div
        onClick={() => {
          setPopupActive(false);
          props.closePopup();
        }}
        className={`${styles["overlay"]} ${
          popupActive ? "" : styles["hidden"]
        }`}
      ></div>
      <div
        className={`${styles["popup-container"]} ${
          popupActive ? "" : styles["hidden"]
        }`}
      >
        <div
          className={styles["close-icon"]}
          onClick={() => {
            setPopupActive(false);
            props.closePopup();
          }}
        >
          {closeIcon}
        </div>
        <div className={styles["popup-icon"]}>{svgIcon}</div>
        <div className={`${styles["popup-status"]} ${styles["success"]}`}>
          Email Verified Successfully
        </div>
        <div className={styles["popup-message"]}>Please Login to Continue</div>
      </div>
    </>
  );
};

export default EmailVerifiedPopup;
