import axios from "axios";
import { z } from "zod";

export const logError = (err: any) => {
  if (axios.isAxiosError(err)) {
    console.log(err.response?.data);
  } else if (err instanceof z.ZodError) {
    err.issues.forEach((issue) => {
      console.log(`${issue.message} inside ${issue.path}`);
    });
  } else if (err instanceof Error) {
    console.log(err);
  } else {
    console.log("err is not Instance of Error");
  }
};
