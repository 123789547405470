import React, { ReactElement, useEffect, useState } from "react";
import Icons from "./Icons/Icons";
import "./Menu.css";
import { useLocation, useNavigate } from "react-router-dom";
// fix issues with object looping here

const svgIcons = [
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.16683 0.833374V3.16671H0.833496V0.833374H3.16683ZM8.16683 10.8334V13.1667H5.8335V10.8334H8.16683ZM3.16683 10.8334V13.1667H0.833496V10.8334H3.16683ZM3.16683 5.83337V8.16671H0.833496V5.83337H3.16683ZM8.16683 5.83337V8.16671H5.8335V5.83337H8.16683ZM13.1668 3.16671H10.8335V0.833374H13.1668V3.16671ZM8.16683 0.833374V3.16671H5.8335V0.833374H8.16683ZM13.1668 5.83337V8.16671H10.8335V5.83337H13.1668ZM13.1668 10.8334V13.1667H10.8335V10.8334H13.1668Z"
      fill="black"
      stroke="black"
    />
  </svg>,
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99992 1.91671C7.96659 1.91671 8.74992 2.70004 8.74992 3.66671C8.74992 4.63337 7.96659 5.41671 6.99992 5.41671C6.03325 5.41671 5.24992 4.63337 5.24992 3.66671C5.24992 2.70004 6.03325 1.91671 6.99992 1.91671ZM6.99992 9.41671C9.47492 9.41671 12.0833 10.6334 12.0833 11.1667V12.0834H1.91659V11.1667C1.91659 10.6334 4.52492 9.41671 6.99992 9.41671ZM6.99992 0.333374C5.15825 0.333374 3.66659 1.82504 3.66659 3.66671C3.66659 5.50837 5.15825 7.00004 6.99992 7.00004C8.84159 7.00004 10.3333 5.50837 10.3333 3.66671C10.3333 1.82504 8.84159 0.333374 6.99992 0.333374ZM6.99992 7.83337C4.77492 7.83337 0.333252 8.95004 0.333252 11.1667V13.6667H13.6666V11.1667C13.6666 8.95004 9.22492 7.83337 6.99992 7.83337Z"
      fill="black"
    />
  </svg>,
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 1.91663L13 0.666626L11.75 1.91663L10.5 0.666626L9.25 1.91663L8 0.666626L6.75 1.91663L5.5 0.666626L4.25 1.91663L3 0.666626V12.3333H0.5V14.8333C0.5 16.2166 1.61667 17.3333 3 17.3333H13C14.3833 17.3333 15.5 16.2166 15.5 14.8333V0.666626L14.25 1.91663ZM10.5 15.6666H3C2.54167 15.6666 2.16667 15.2916 2.16667 14.8333V14H10.5V15.6666ZM13.8333 14.8333C13.8333 15.2916 13.4583 15.6666 13 15.6666C12.5417 15.6666 12.1667 15.2916 12.1667 14.8333V12.3333H4.66667V3.16663H13.8333V14.8333Z"
      fill="black"
    />
    <path d="M10.5 4.83329H5.5V6.49996H10.5V4.83329Z" fill="black" />
    <path d="M13 4.83329H11.3333V6.49996H13V4.83329Z" fill="black" />
    <path d="M10.5 7.33329H5.5V8.99996H10.5V7.33329Z" fill="black" />
    <path d="M13 7.33329H11.3333V8.99996H13V7.33329Z" fill="black" />
  </svg>,
];

export enum TabsName {
  DASHBOARD = "Dashboard",
  BILLING = "Billing",
  ACCOUNTS = "Accounts",
  NOTIFICATION = "Notification",
}

const icons: Record<
  number,
  {
    name: TabsName;
    icon: JSX.Element;
    path: string;
  }
> = {
  1: {
    name: TabsName.DASHBOARD,
    icon: svgIcons[0],
    path: "/dashboard",
  },
  2: {
    name: TabsName.BILLING,
    icon: svgIcons[2],
    path: "/billing",
  },
  3: {
    name: TabsName.ACCOUNTS,
    icon: svgIcons[1],
    path: "/accounts/brokerage",
  },
  4: {
    name: TabsName.NOTIFICATION,
    icon: svgIcons[1],
    path: "/accounts/notification",
  },
};

type MenuProps = {
  clickHandler: () => void;
};

function Menu(props: MenuProps) {
  const [selected, setSelected] = useState<number>(1);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const matchLoactionToIcon = () => {
      for (let key of Object.keys(icons).map(Number)) {
        if (location.pathname === icons[key].path) return key;
      }
      return 1;
    };
    setSelected(matchLoactionToIcon());
  }, [location.pathname]);

  const handleSelection = (id: number) => {
    if (selected === id) return;

    setSelected(id);
    navigate(icons[id].path);
    props.clickHandler();
  };

  const IconsArray = () => {
    let iconsArray: ReactElement[] = [];
    for (let i = 1; i <= Object.keys(icons).length; i++) {
      iconsArray.push(
        <Icons
          className={selected === i ? "icon-active" : ""}
          iconInfo={icons[i]}
          onClick={() => handleSelection(i)}
        />
      );
    }
    return iconsArray;
  };

  return <div className="menu-container">{IconsArray()}</div>;
}

export default Menu;
