import React, { useEffect, useState } from "react";
import UnavailCards from "./AccountsCard/UnavailCards";
import "./AccountsFunction.css";
import AvailableCard from "./AccountsCard/AvailableCard";
import {
  useAccountId,
  useBroker,
  useBrokerage,
  useBrokerageLoading,
  useNotification,
  usePrimaryAccountId,
  useSub,
} from "../../../../store/store";
import {
  AccountKind,
  BrokerageAccount,
  SubscriptionStatus,
} from "../../../../types/types";
import { User, useAuth0 } from "@auth0/auth0-react";
import { logError } from "../../../../helper/logError.helper";
import getPrimaryAccountId from "../../../../helper/getPrimaryAccountId.helper";
import { getAccountsFunction } from "../../../../shared/accountFunction";

function AccountsFunction() {
  const [, setBrokerageState] = useBrokerage();
  const [accountId, setAccountId] = useAccountId();
  const [, setNotificationState] = useNotification();
  const [broker, setBroker] = useBroker();
  const [, setPrimaryAccountId] = usePrimaryAccountId();
  const [sub, setSub] = useSub();
  const { isAuthenticated, user, isLoading } = useAuth0();
  const [isBrokerageLoading] = useBrokerageLoading();
  const [brokerageAccounts, setBrokerageAccounts] = useState<
    BrokerageAccount[] | null
  >(null);
  const [isBusy, setIsBusy] = useState(true);

  const accountsFunction = broker && getAccountsFunction(broker);

  useEffect(() => {
    const fetchData = async (user: User) => {
      try {
        if (isBrokerageLoading) return;
        if (!accountsFunction) throw new Error("Broker Not Found");
        // fetch the current broker
        //fetch all the accounts
        const { brokerageAccounts } =
          await accountsFunction.getBrokerageAccounts(user);

        // todo: next 2 lines can be added, not adding because already TD refreshes the page when it gets setup
        // setAccountId(accountId);
        // setPrimaryAccountId(getPrimaryAccountId(brokerageAccounts));
        setBrokerageAccounts(brokerageAccounts);
      } catch (err) {
        logError(err);
      }
      setIsBusy(false);
    };

    if (isLoading) return;
    if (!user) return; // Error Handling TODO
    fetchData(user);
  }, [
    accountsFunction,
    isAuthenticated,
    isBrokerageLoading,
    isLoading,
    setAccountId,
    setPrimaryAccountId,
    user,
  ]);

  useEffect(() => {
    const startTradeDefault = async () => {
      if (!accountId || !accountsFunction || !brokerageAccounts) return;

      if (sub === false && brokerageAccounts.length === 1) {
        await accountsFunction.startTrade(accountId);
        setSub(SubscriptionStatus.SUBSCRIBED);
      }
    };
    startTradeDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, brokerageAccounts, accountsFunction]);

  const handleUnauthenticateOnClick = async () => {
    try {
      if (!accountId || !accountsFunction) return;
      // unauthenticate
      if (sub === true) {
        await accountsFunction.stopTrade(accountId);
      }
      await accountsFunction.unauthorize(accountId);

      setNotificationState(false);
      setPrimaryAccountId(null);
      setBrokerageState(false);
      setAccountId(null);
      setBroker(null);
    } catch (err) {
      logError(err);
    }
  };

  // todo: add check for broker before displaying anything
  return isBusy ? (
    <></>
  ) : (
    <div>
      <div className="interactive-heading">{broker}</div>
      {brokerageAccounts &&
        brokerageAccounts.map((brokerageAccount) => {
          if (brokerageAccount.AccountKind === AccountKind.PRIMARY) {
            return <AvailableCard brokerageAccount={brokerageAccount} />;
          } else {
            return <UnavailCards brokerageAccount={brokerageAccount} />;
          }
        })}
      <div className="unauthenticate-broker__inter">
        <button onClick={handleUnauthenticateOnClick}>
          Unauthenticate {broker}
        </button>
      </div>
      <div className="brokerage--Notice">
        <div>Note</div>
        <p>
          Only the{" "}
          {brokerageAccounts
            ? getPrimaryAccountId(brokerageAccounts)
            : "123456"}{" "}
          account can be subscibed, as it is primary account. If you would like
          to subscribe any other account, please re-authenticate.
        </p>
      </div>
    </div>
  );
}

export default AccountsFunction;
