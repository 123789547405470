import React, { useState } from "react";
import styles from "./Landing.module.css";
import { ReactComponent as LogoIcon } from "./../../assets/logo.svg";
import { ReactComponent as TwitterIcon } from "./../../assets/twitter.svg";
import { ReactComponent as FacebookIcon } from "./../../assets/facebook.svg";
import { ReactComponent as YoutubeIcon } from "./../../assets/youtube.svg";
import { ReactComponent as LinkedinIcon } from "./../../assets/linkedIn.svg";
import { ReactComponent as DoubleArrowIcon } from "./../../assets/double_arrow.svg";
import { ReactComponent as HamsIcon } from "./../../assets/charm_menu-hamburger-2.svg";
import { ReactComponent as CloseIcon } from "./../../assets/eva_close-outline.svg";
import AmicoIcon from "./../../assets/amico.svg";
import StrategyIcon from "./../../assets/strategy.svg";
import HeroImg from "./../../assets/silhouette_city.svg";
import { useNavigate } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { logError } from "../../helper/logError.helper";

const LINKS = {
  FACEBOOK: "https://facebook.com/luptoncapital",
  TWITTER: "https://twitter.com/luptoncapital",
  YOUTUBE: "https://www.youtube.com/channel/UCnNV58dwf5X7dkrvCE18Djw/videos",
  LINKEDIN: "https://linkedin.com/company/luptoncapital",
};

const NewLanding = () => {
  const navigate = useNavigate();
  const { loginWithPopup, isAuthenticated } = useAuth0();
  const [mobileView, setMobileView] = useState<true | false>(false);

  const loginHandler = async () => {
    setMobileView(false);
    try {
      if (isAuthenticated) {
        navigate("/dashboard");
        return;
      }
      localStorage.clear();
      await loginWithPopup();
      navigate("/usersync");
    } catch (err) {
      logError(err);
    }
  };

  return (
    <div className={styles["select-font"]}>
      {/* Topbar */}
      <div className={styles["top-bar"]}>
        Get alerts and order preparation for proven trading strategies
      </div>

      {/* Navbar */}
      <nav className={styles["main-nav"]}>
        <div>
          <LogoIcon className={styles["main-logo-icon"]} />
        </div>
        <div className={styles["nav-center-menu"]}>
          <div>
            <a href="#about-us" rel="no-referrer">
              About Me
            </a>
          </div>
          <div>
            <a href="#strategies" rel="no-referrer">
              Strategy
            </a>
          </div>
          <div>
            <a href="#how-it-works" rel="no-referrer">
              How it works
            </a>
          </div>
        </div>
        <div className={styles["nav-end"]}>
          <div className={styles["get-start-btn-cont"]}>
            <button onClick={loginHandler}>GET STARTED</button>
          </div>
          <div>
            <a href={LINKS.TWITTER} rel="noreferrer" target="_blank">
              <TwitterIcon />
            </a>
          </div>
          <div>
            <a href={LINKS.FACEBOOK} rel="noreferrer" target="_blank">
              <FacebookIcon />
            </a>
          </div>
          <div>
            <a href={LINKS.YOUTUBE} rel="noreferrer" target="_blank">
              <YoutubeIcon />
            </a>
          </div>
          <div>
            <a href={LINKS.LINKEDIN} rel="noreferrer" target="_blank">
              <LinkedinIcon />
            </a>
          </div>
        </div>
        <div className={styles["nav-hams-menu"]}>
          <div>
            <HamsIcon
              onClick={() => setMobileView(true)}
              className={styles["hams-sytle"]}
            />
          </div>
        </div>
      </nav>

      {/* Mobile Nav */}
      <nav className={mobileView ? styles["mobile-nav"] : styles["hiddenMenu"]}>
        <div className={styles["mobile-top-bar"]}>
          <div>
            <LogoIcon className={styles["mobile-logo-icon"]} />
          </div>
          <div>
            <CloseIcon
              onClick={() => setMobileView(false)}
              className={styles["close-icon"]}
            />
          </div>
        </div>
        <div className={styles["mobile-sec-nav"]}>
          <div>
            <a
              href="#about-us"
              rel="no-referrer"
              onClick={() => setMobileView(false)}
            >
              About Me
            </a>
          </div>
          <div>
            <a
              href="#strategies"
              rel="no-referrer"
              onClick={() => setMobileView(false)}
            >
              Strategy
            </a>
          </div>
          <div>
            <a
              href="#how-it-works"
              rel="no-referrer"
              onClick={() => setMobileView(false)}
            >
              How it works
            </a>
          </div>
          <div className={styles["separator--line-nav"]} />
        </div>
        <div className={styles["mobile-last-nav"]}>
          <div className={styles["get-start-btn-mobile"]}>
            <button onClick={loginHandler}>GET STARTED</button>
          </div>
          <div className={styles["mobile-csoiallogin"]}>
            <div>
              <a href={LINKS.TWITTER} rel="noreferrer" target="_blank">
                <TwitterIcon className={styles["social-media-icons"]} />
              </a>
            </div>
            <div>
              <a href={LINKS.FACEBOOK} rel="noreferrer" target="_blank">
                <FacebookIcon className={styles["social-media-icons"]} />
              </a>
            </div>
            <div>
              <a href={LINKS.YOUTUBE} rel="noreferrer" target="_blank">
                <YoutubeIcon className={styles["social-media-icons"]} />
              </a>
            </div>
            <div>
              <a href={LINKS.LINKEDIN} rel="noreferrer" target="_blank">
                <LinkedinIcon className={styles["social-media-icons"]} />
              </a>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <section className={styles["hero-section"]}>
        <div className={styles["background-box"]} />
        <div className={styles["hero-container"]}>
          <div className={styles["hero-content"]}>
            <div className={styles["hero-title"]}>
              <span className={styles["jonah-trade-styles"]}>
                Trade Like Jonah
              </span>{" "}
              : Real-Time Alerts, Auto Orders, and Proven Strategies
            </div>
            <p className={styles["hero-text"]}>
              Signup today to begin receiving Real-time Alerts and Automatic
              Order Preparation for Jonah's trading portfolio which includes his
              entry/exit strategy for every position and his disciplined risk
              management with proper position sizing.
            </p>
            <button className={styles["hero-btn"]} onClick={loginHandler}>
              <span>Get Started Today</span>{" "}
              <span style={{ display: "flex" }}>
                <DoubleArrowIcon className={styles["arrows"]} />
              </span>
            </button>
          </div>
          <div className={styles["hero-img"]}>
            <img src={HeroImg} alt="hero-img" />
          </div>
        </div>
        <div className={styles["hero-footer-text"]}>
          For the first time ever you can get{" "}
          <span className={styles["hero-sub-para"]}>
            exclusive real-time alerts
          </span>{" "}
          to Jonah's successful, time-tested trading strategy.
        </div>
        <div className={styles["separator"]} />
      </section>

      {/* About Me */}
      <section id="about-us">
        <div className={styles["about-me"]}>
          <div className={styles["about-heading"]}>About Me</div>
          <p className={styles["about-me-para"]}>
            Hi there, my name is <b>Jonah Lupton</b>, I currently live in
            downtown Boston and I've been an investor and trader for{" "}
            <b>20+ years</b>. I started my career at the Wall Street investment
            firms where I managed capital for HNW families. At the present time
            I only manage my personal capital while running several different
            investment services that provide my subscribers with access to my
            stock research and my two portfolios/strategies. Until now I never
            had a service that allowed my subscribers to receive an alert and
            see my activity in real-time then decide if they want to replicate
            that buy order in their own account with the click of a button.
          </p>
          <p className={styles["about-me-para"]}>
            As of early November, my trading portfolio is up <b>~73%</b> YTD
            which is inline with my annualised returns over the past several
            years.
          </p>
        </div>
        <div
          className={`${styles["separator"]} ${styles["about-separator"]}`}
        />
      </section>

      {/* Strategy */}
      <section id="strategies" className={styles["strategy-section"]}>
        <div className={styles["strategy-container"]}>
          <div>
            <img src={StrategyIcon} alt="strategy" />
          </div>
          <div>
            <div className={styles["strategy-title"]}>Strategy</div>
            <p className={styles["strategy-content"]}>
              Within my trading strategy I'm looking for stocks with strong
              fundamentals and strong technicals, good relative strength,
              institutional accumulation, higher revenue & earnings revisions
              and positive reactions to quarterly earnings.
            </p>
            <p className={styles["strategy-content"]}>
              I consider myself a swing/position trader so I'm typically trying
              to hold positions for several days, weeks or months - it all
              depends on the market conditions and how long my positions are
              working for me and respecting the moving averages or key support
              levels.
            </p>
            <p className={styles["strategy-content"]}>
              Over the course of a normal year, I'm usually targeting a 40-45%
              win rate on my trades with my average winner at ~6% and my average
              loser at ~2%. Every trading strategy is going to require risk
              taking but it needs to be calculated and disciplined. I never get
              into any trade without knowing where my stop loss will be set
              which is typically 2-3% below my entry price, however once a trade
              is in the green I'm quick to move up my stop loss to make sure a
              winner doesn't turn into a loser.
            </p>
            <p className={styles["strategy-content"]}>
              Most people don't have time to be sitting in front of their
              computer screens all day, staring at charts and trying to trade
              the markets but this is what I do every day and I do it very well.
              I spend ~2 hours every morning going through hundreds of charts to
              find the best trading setups so once the markets open I am focused
              and ready to attack. For the first time ever I'm giving my
              subscribers a chance to join me in my trading strategy. Let me
              help you increase your portfolio returns while taking away some of
              the time and stress that comes with being a retail trader.
            </p>
          </div>
        </div>
        <div
          className={`${styles["separator"]} ${styles["about-separator"]}`}
        />
      </section>

      {/* How it works */}
      <section id="how-it-works">
        <div className={styles["work-container-support"]}>
          <div className={styles["working-how"]}>How It Works</div>
          <p className={styles["regular-paras-for-work"]}>
            Once you become a subscriber, you will be asked to connect your
            brokerage account with our system and then set up your notification
            channel which is only WhatsApp for now.
          </p>
          <p className={styles["how-works-specific"]}>
            Every time I make a trade you will receive an alert within 1 second.
          </p>
          <p className={styles["regular-paras-for-work"]}>
            That exact order (on a percentage basis) will be prepared for you in
            your brokerage account and with one-click that order will be
            executed.
          </p>
          <p className={styles["work-ethics-heading"]}>Benefits</p>
          <ol className={styles["work-ordered-list"]}>
            <li>
              Real-time trading strategies from an experienced professional
            </li>
            <li>
              Free your yourself to do other things instead of staring at charts
              and screens all day
            </li>
            <li>
              Reduce the stress and anxiety of trying to manage your own
              portfolio every day
            </li>
            <li>Maintain control and flexibility of your portfolio</li>
            <li>
              Learn from Jonah through his weekly newsletters and webcasts
            </li>
          </ol>
          <p className={styles["work-ethics-heading"]}>Disclosures</p>
          <p className={styles["regular-paras-for-work"]}>
            We are not financial advisors and we do not provide investment
            advice. We are not registered with the SEC or FINRA. We recommend
            that anyone using our services does their own due diligence and
            considers their personal risk tolerance and time horizon before
            making any investment decisions
          </p>
        </div>
        <div
          className={`${styles["separator"]} ${styles["about-separator"]}`}
        />
      </section>

      {/* Early Bird offer */}
      <section id="early-bird-offer-section">
        <div className={styles["offer-container"]}>
          <div className={styles["offer-bird"]}>
            <div className={styles["early-bird-offer"]}>Early Bird Offer!</div>
            <div className={styles["offer-cards"]}>
              <div className={styles["card-details"]}>
                <div>$39/month or $399/year</div>
                <div>(through December 31st, 2023)</div>
              </div>
              <div className={styles["card-details"]}>
                <div>$59/month or $599/year</div>
                <div>(starting January 1st, 2024)</div>
              </div>
            </div>
            <div className={styles["offer-text"]}>
              Ready to take your portfolio to the next level?
            </div>
            <button className={styles["hero-btn"]} onClick={loginHandler}>
              <span>Get Started</span>{" "}
              <span style={{ display: "flex" }}>
                <DoubleArrowIcon className={styles["arrows"]} />
              </span>
            </button>
          </div>
          <div className={styles["offer-img"]}>
            <div>
              <img src={AmicoIcon} alt="offers" />
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className={styles["footer-class"]}>
        <div>
          <LogoIcon className={styles["footer-logo"]} />
        </div>
        <div className={styles["footer-middle"]}>
          <div>
            <a href="/privacy" rel="no-referrer">
              Privacy Policy
            </a>
          </div>
          <div>
            <a href="/terms" rel="no-referrer">
              Terms of Use
            </a>
          </div>
        </div>
        <div className={styles["social-icons"]}>
          <div>
            <a href={LINKS.TWITTER} rel="noreferrer" target="_blank">
              <TwitterIcon className={styles["social-icons__"]} />
            </a>
          </div>
          <div>
            <a href={LINKS.FACEBOOK} rel="noreferrer" target="_blank">
              <FacebookIcon className={styles["social-icons__"]} />
            </a>
          </div>
          <div>
            <a href={LINKS.YOUTUBE} rel="noreferrer" target="_blank">
              <YoutubeIcon className={styles["social-icons__"]} />
            </a>
          </div>
          <div>
            <a href={LINKS.LINKEDIN} rel="noreferrer" target="_blank">
              <LinkedinIcon className={styles["social-icons__"]} />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default NewLanding;
