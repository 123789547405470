import React, { useState } from "react";
import ErrorNotification from "./Error/ErrorNotification";
import SetupNotification from "./Setup/SetupNotification";
import ConfiguredNotification from "./Configured/ConfiguredNotification";
import {
  useBilling,
  useBillingLoading,
  useBrokerageLoading,
  useNotification,
  useNotificationLoading,
  useSub,
} from "../../../store/store";
import { Link } from "react-router-dom";
import styles from "./Notification.module.css";
import { useIsVisible } from "../../../hooks/useIsVisible";

const Notification = () => {
  const [sBilling] = useBilling();
  const [sNotification] = useNotification();
  const [sSub] = useSub();
  const [isBrokerageLoading] = useBrokerageLoading();
  const [isNotificationLoading] = useNotificationLoading();
  const [isBillingLoading] = useBillingLoading();
  const [, isAccountsVisible, isNotificationVisible] = useIsVisible();

  const [isUpdate, setIsUpdate] = useState(false);

  const isBusy =
    isBillingLoading ||
    (isAccountsVisible && isBrokerageLoading) ||
    isNotificationLoading;

  return !isBusy && isNotificationVisible ? (
    <>
      {/* state 1 */}
      {(!sBilling || (isAccountsVisible && !sSub)) && <ErrorNotification />}

      {/* state 2 */}
      {sBilling && (!isAccountsVisible || sSub) && !sNotification && (
        <SetupNotification useIsUpdate={{ isUpdate, setIsUpdate }} />
      )}

      {/* state 3 */}
      {sBilling &&
        (!isAccountsVisible || sSub) &&
        sNotification &&
        isUpdate && (
          <SetupNotification useIsUpdate={{ isUpdate, setIsUpdate }} />
        )}

      {/* state 4 */}
      {sBilling &&
        (!isAccountsVisible || sSub) &&
        sNotification &&
        !isUpdate && <ConfiguredNotification setIsUpdate={setIsUpdate} />}

      <div className={styles["policy"]}>
        <Link className={styles["link"]} to="/privacy">
          Privacy Policy
        </Link>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <Link className={styles["link"]} to="/terms">
          Terms of Service
        </Link>
      </div>
    </>
  ) : (
    <></>
  );
};

export default Notification;
