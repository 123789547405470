import { AccountKind, BrokerageAccount } from "../types/types";

const getPrimaryAccountId = (brokerageAccounts: BrokerageAccount[]) => {
  let primaryAccountId: string;

  brokerageAccounts.forEach((brokerageAccount) => {
    if (brokerageAccount.AccountKind === AccountKind.PRIMARY) {
      primaryAccountId = brokerageAccount.BrokerageAccountId;
    }
  });

  return primaryAccountId!;
};

export default getPrimaryAccountId;
