import React from "react";
import styles from "./Popup.module.css";

const svgIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <path
      d="M32 0C14.1714 0 0 14.1714 0 32C0 49.8286 14.1714 64 32 64C49.8286 64 64 49.8286 64 32C64 14.1714 49.8286 0 32 0ZM44.3429 48L32 35.6571L19.6571 48L16 44.3429L28.3429 32L16 19.6571L19.6571 16L32 28.3429L44.3429 16L48 19.6571L35.6571 32L48 44.3429L44.3429 48Z"
      fill="#EF5858"
    />
  </svg>
);

const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
      fill="#CCCCCC"
    />
  </svg>
);

type ErrroPopupProps = {
  closePopup: () => void;
};

const ErrorPopup = (props: ErrroPopupProps) => {
  const [popupActive, setPopupActive] = React.useState(true);

  return (
    <>
      <div
        onClick={() => {
          setPopupActive(false);
          props.closePopup();
        }}
        className={`${styles["overlay"]} ${
          popupActive ? "" : styles["hidden"]
        }`}
      ></div>
      <div
        className={`${styles["popup-container"]} ${
          popupActive ? "" : styles["hidden"]
        }`}
      >
        <div
          className={styles["close-icon"]}
          onClick={() => {
            setPopupActive(false);
            props.closePopup();
          }}
        >
          {closeIcon}
        </div>
        <div className={styles["popup-icon"]}>{svgIcon}</div>
        <div className={`${styles["popup-status"]} ${styles["error"]}`}>
          Payment Failed
        </div>
        <div className={styles["popup-message"]}>
          Oops, Payment Failed, Please try again later
        </div>
      </div>
    </>
  );
};

export default ErrorPopup;
