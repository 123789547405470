import { useEffect, useState } from "react";
import {
  useBilling,
  useBillingDetails,
  useBillingLoading,
} from "../store/store";
import { UserType } from "../types/types";

export const useIsVisible = () => {
  const [isBillingVisible, setIsBillingVisible] = useState(false);
  const [isAccountsVisible, setIsAccountsVisible] = useState(false);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  const [sBilling] = useBilling();
  const [billingDetails] = useBillingDetails();
  const [isBillingLoading] = useBillingLoading();

  useEffect(() => {
    if (isBillingLoading || billingDetails?.user_type === UserType.TRADER)
      setIsBillingVisible(false);
    else setIsBillingVisible(true);

    if (isBillingLoading || !sBilling || billingDetails?.alerts_only)
      setIsAccountsVisible(false);
    else setIsAccountsVisible(true);

    if (
      isBillingLoading ||
      !sBilling ||
      billingDetails?.user_type === UserType.TRADER
    ) {
      setIsNotificationVisible(false);
    } else setIsNotificationVisible(true);
  }, [
    billingDetails?.alerts_only,
    billingDetails?.user_type,
    isBillingLoading,
    sBilling,
  ]);

  return [isBillingVisible, isAccountsVisible, isNotificationVisible] as const;
};
