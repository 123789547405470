import authObj, { GetIdTokenClaims, LogOutFunc } from "../shared/authObject";

export const setAuthObj = (
  getIdTokenClaims: GetIdTokenClaims,
  logoutFunc: LogOutFunc
) => {
  if (!authObj.getIdTokenClaims) {
    authObj.getIdTokenClaims = getIdTokenClaims;
    authObj.logoutFunc = logoutFunc;
  }
};
