import { User } from "@auth0/auth0-react";
import { getUserIfAlreadyExist } from "../services/api/user.service";

export const getUserId = async (user: User) => {
  try {
    let userId = localStorage.getItem("userId");

    if (!userId) {
      const data = await getUserIfAlreadyExist(user);
      userId = data.userId;
    }

    return userId;
  } catch (err) {
    throw err;
  }
};
