import React from "react";
import styles from "./Dashboard.module.css";
import { useBilling, useNotification, useSub } from "../../store/store";
import BillingDashboard from "./Segments/BillingDashboard";
import AccountsDashboard from "./Segments/AccountsDashboard";
import NotificationDashboard from "./Segments/NotificationDashboard";
import { useIsVisible } from "../../hooks/useIsVisible";

const DashboardNew = () => {
  const [sBilling] = useBilling();
  const [sNotification] = useNotification();
  const [sSub] = useSub();
  const [isBillingVisible, isAccountsVisible, isNotificationVisible] =
    useIsVisible();

  const isConfigurationIncomplete =
    (isBillingVisible && !sBilling) ||
    (isAccountsVisible && !sSub) ||
    (isNotificationVisible && !sNotification);

  return (
    <>
      <div className={styles["dashboard-header"]}>
        Your account configuration is{" "}
        <span className={styles["acc-status"]}>
          {isConfigurationIncomplete && "in"}complete.{" "}
        </span>
        Lupton Capital's trade alerts will{" "}
        <span className={styles["acc-status"]}>
          {isConfigurationIncomplete && "not "}
        </span>
        be sent to your notification channel.
      </div>
      <div className={styles["configuration-container"]}>
        {isBillingVisible && <BillingDashboard />}
        {isAccountsVisible && <AccountsDashboard />}
        {isNotificationVisible && <NotificationDashboard />}
      </div>
    </>
  );
};

export default DashboardNew;
