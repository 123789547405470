import { customAxios } from "../axios.service";
import * as inputs from "../../helper/api/bills.helper";
import * as schema from "../../types/response/bills.type";

// 1
export const checkout = async (price_id: string) => {
  const formData = inputs.getCheckoutInput(price_id);
  const { data } = await customAxios.billing.post("checkout", formData);
  // todo: check for validity
  return data;
};

// 2
export const getSubscriptionPlans = async () => {
  const { data } = await customAxios.billing.get("plans");
  // todo: check for validity
  return data;
};

// 3
export const getSubscriptionStatus = async () => {
  const { data } = await customAxios.billing.get("status");
  let d = schema.getSubscriptionStatusSchema.parse(data);

  return d;
};

// 4
export const deleteSubscription = async () => {
  const { data } = await customAxios.billing.delete("cancel");
  // todo: check for validity
  return data;
};

// 5
export const getInvoiceHistory = async () => {
  const { data } = await customAxios.billing.get("history");
  let d = schema.getInvoiceHistorySchema.parse(data);

  return d;
};
