import styles from "./IBKRStatus.module.css";
import statusIcon from "./../../../../assets/status.png";
import { useSearchParams } from "react-router-dom";
import {
  useAccountId,
  useBroker,
  useBrokerage,
  useIBKRStatuses,
  useNotification,
} from "../../../../store/store";
import { logError } from "../../../../helper/logError.helper";
import { getIBKRSubscriptionStatus } from "../../../../services/api/notification.service";
import { useState } from "react";

const svgIcons = [
  <svg
    width="9"
    height="17"
    viewBox="0 0 9 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.25 16L0.75 8.5" stroke="black" stroke-linecap="round" />
    <path d="M8.25 1L0.75 8.5" stroke="black" stroke-linecap="round" />
  </svg>,
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    style={{ cursor: "pointer" }}
  >
    <path
      d="M17.6498 6.34989C16.8116 5.50416 15.7942 4.8575 14.6726 4.45768C13.551 4.05786 12.3539 3.91508 11.1698 4.03989C7.49978 4.40989 4.47978 7.38989 4.06978 11.0599C3.51978 15.9099 7.26978 19.9999 11.9998 19.9999C13.5099 19.9999 14.989 19.5714 16.2653 18.7642C17.5415 17.957 18.5626 16.8043 19.2098 15.4399C19.5298 14.7699 19.0498 13.9999 18.3098 13.9999C17.9398 13.9999 17.5898 14.1999 17.4298 14.5299C16.8492 15.7789 15.8561 16.7899 14.6177 17.3927C13.3793 17.9955 11.971 18.1534 10.6298 17.8399C8.40978 17.3499 6.61978 15.5399 6.14978 13.3199C5.95221 12.4422 5.9545 11.5312 6.15647 10.6545C6.35845 9.77775 6.75494 8.95764 7.31663 8.25481C7.87831 7.55198 8.59082 6.98442 9.40145 6.59411C10.2121 6.20379 11.1001 6.00071 11.9998 5.99989C13.6598 5.99989 15.1398 6.68989 16.2198 7.77989L14.7098 9.28989C14.0798 9.91989 14.5198 10.9999 15.4098 10.9999H18.9998C19.5498 10.9999 19.9998 10.5499 19.9998 9.99989V6.40989C19.9998 5.51989 18.9198 5.06989 18.2898 5.69989L17.6498 6.34989Z"
      fill="#999"
    />
  </svg>,
];

const IBKRStatus = () => {
  const [query, setQuery] = useSearchParams();
  const [, setBroker] = useBroker();
  const [, setBrokerageState] = useBrokerage();
  const [ibkrStatuses, setibkrStatuses] = useIBKRStatuses();
  const [, setNotificationState] = useNotification();
  const [, setAccountId] = useAccountId();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleDownlaodButtonOnClick = () => {
    query.set("popup", "ibkr_download");
    setQuery(query);
  };

  const handleBackButtonOnClick = () => {
    localStorage.removeItem("download_requested");
    setNotificationState(false);
    setBrokerageState(false);
    setAccountId(null);
    setBroker(null);
  };

  const handleRefreshStatusOnClick = async () => {
    setIsRefreshing(true);
    try {
      const data = await getIBKRSubscriptionStatus();
      setibkrStatuses(data);
    } catch (err) {
      logError(err);
    }
    setIsRefreshing(false);
  };

  return (
    <div className={styles["status-container"]}>
      <div className={styles["container"]}>
        <div className={styles["content-header"]}>
          <div className={styles["img-content"]}>
            <img src={statusIcon} alt="status" />
          </div>
          <div
            className={styles.ikbr_refresh}
            onClick={handleRefreshStatusOnClick}
          >
            IBKR Status {!isRefreshing && svgIcons[1]}
          </div>
        </div>
        <div className={styles["status-wrap-container"]}>
          <div className={styles["header-wrap-status"]}>
            <div>
              <button
                className={styles["download-btn"]}
                onClick={handleDownlaodButtonOnClick}
              >
                Download
              </button>{" "}
              the app to continue.
            </div>
          </div>
          <div className={styles["status-wrapper"]}>
            <div className={styles["title"]}>Tradezy Authentication Status</div>
            <div
              className={`${styles["active-dot"]} ${
                ibkrStatuses.authentication
                  ? styles["green-color"]
                  : styles["red-color"]
              }`}
            />
          </div>
          {/* <div className={styles["login-button-container"]}>
            <button>Log In</button>
          </div> */}
          <div className={styles["status-wrapper"]}>
            <div className={styles["title"]}>
              Tradezy Order Preparation Status
            </div>
            <div
              className={`${styles["active-dot"]} ${
                ibkrStatuses.orderPrep
                  ? styles["green-color"]
                  : styles["red-color"]
              }`}
            />
          </div>
          <div className={styles["status-wrapper"]}>
            <div className={styles["title"]}>IBKR Connection Status</div>
            <div
              className={`${styles["active-dot"]} ${
                ibkrStatuses.ibkrConnection
                  ? styles["green-color"]
                  : styles["red-color"]
              }`}
            />
          </div>
        </div>
        <div
          className={styles["disconnect-ikbr"]}
          onClick={handleBackButtonOnClick}
        >
          <button>Disconnect IBKR</button>
        </div>
      </div>
    </div>
  );
};

export default IBKRStatus;
