import { z } from "zod";
import { BrokerageAccount, NotificationType, SlackAuthStatus } from "../types";
import { getBrokerageAccountsSchema } from "./accounts.type";

export const slackAuthStatusSchema = z.object({
  status: z.custom<SlackAuthStatus>(),
});

export const slackUserExistsSchema = z.object({
  status: z.string(), // to do -> change it to enum
  url: z.string(),
});

export const slackJoinUserSchema = z.object({
  url: z.string(),
});

export const enableNotificationSchema = z.object({
  content: z.string(),
});

export const updateNotificationSchema = z.object({
  account_id: z.string(),
  slack_user_id: z.string(),
  phone: z.string().length(10),
  notification_type: z.custom<NotificationType>(),
});

export const disableNotificationSchema = z.object({
  status: z.string(), // to do -> change to enum
});

export const notificationStatusSchema = z.object({
  account_id: z.string(),
  slack_user_id: z.string().nullable(),
  phone: z.string().length(10).nullable(),
  notification_type: z.custom<NotificationType>(),
});

export const getIBKRSubscriptionStatusSchema = z.object({
  authentication: z.boolean(),
  orderPrep: z.boolean(),
  ibkrConnection: z.boolean(),
  payloadType: z.string(),
});

export const getIBKRDownloadLinksSchema = z.object({
  mac: z.string().nullable(),
  windows: z.string().nullable(),
});

export const getIBKRAccountIdSchema = getBrokerageAccountsSchema;
