import { User } from "@auth0/auth0-spa-js";
import {
  getAuthorizeToIBKRInput,
  getIBAuthStatusInput,
  getIBSubscriptionStatusInput,
  getStartTradeInput,
  getStopTradeInput,
} from "../../helper/api/accounts.helper";
import { IAccountsFunction } from "../../types/types";
import * as schema from "../../types/response/accounts.type";
import axios, { AxiosInstance } from "axios";
import baseUrl from "../../shared/baseUrl";
import { requestHandler } from "../axios.service";
import { getUserType } from "../../helper/userType.helper";
import { getUserId } from "../../helper/userId.helper";
import { deletePortfolio, disableNotification } from "./notification.service";

export class IBAccountsFunction implements IAccountsFunction {
  customAxios: AxiosInstance;

  constructor() {
    this.customAxios = axios.create({
      baseURL: baseUrl.interactiveBroker,
    });
    this.customAxios.interceptors.request.use(requestHandler);
  }

  authorize = async (user: User, username: string, password: string) => {
    const userId = await getUserId(user);
    const formData = getAuthorizeToIBKRInput(username, password, userId);
    const { data } = await this.customAxios.post("authorize", formData);
    let d = schema.AuthorizeIBKRSchema.parse(data);

    return d;
  };

  checkAuthStatus = async (user: User) => {
    const userId = await getUserId(user);
    const formData = getIBAuthStatusInput(userId, getUserType());
    const { data } = await this.customAxios.post("auth/status", formData);
    let d = schema.AuthorizeIBKRSchema.parse(data);

    return d;
  };

  getBrokerageAccounts = async (user: User) => {
    const userId = await getUserId(user);
    const { data } = await this.customAxios.get(`retrieve/user/${userId}`);
    let d = schema.getBrokerageAccountsSchema.parse(data);

    return d;
  };

  checkSubscriptionStatus = async (accountId: string) => {
    const formData = getIBSubscriptionStatusInput(accountId, getUserType());
    const { data } = await this.customAxios.post("socket/status", formData);
    let d = schema.ibStatusSchema.parse(data);

    return d;
  };

  startTrade = async (accountId: string) => {
    const formData = getStartTradeInput(accountId, getUserType());
    await this.customAxios.post("socket/start", formData);
  };

  stopTrade = async (accountId: string) => {
    const formData = getStopTradeInput(accountId, getUserType());
    await this.customAxios.post("socket/stop", formData);
  };

  unauthorize = async (accountId: string) => {
    await disableNotification(accountId);
    await deletePortfolio(accountId);
    await this.customAxios.delete(`revoke/account/${accountId}`);
  };
}
