import { GetIdTokenClaims } from "../shared/authObject";

const getIdToken = async (getIdTokenClaims: GetIdTokenClaims) => {
  try {
    let idToken = localStorage.getItem("idToken");
    if (!idToken) {
      console.log("ID TOKEN: NOT IN LOCAL STORAGE");
      const res = await getIdTokenClaims();
      if (!res) return;
      const { __raw } = res;
      console.log("ID TOKEN: GET REQUEST SUCCESS");
      idToken = __raw;
      localStorage.setItem("idToken", idToken);
    }
    return idToken;
  } catch (err) {
    throw new Error("ID TOKEN: NOT FOUND");
  }
};

export default getIdToken;
