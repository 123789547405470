import { z } from "zod";
import { Invoice, UserType } from "../types";

interface SubscriptionPrice {
  id: string;
  currency: string;
  recurring: {
    interval: string;
    interval_count: number;
  };
  unit_amount: number;
}

export const getSubscriptionPlanSchema = z.object({
  id: z.string(),
  name: z.string(),
  active: z.boolean(),
  default_price: z.string().nullable(),
  price: z.custom<SubscriptionPrice>(),
});

export const getSubscriptionStatusSchema = z.object({
  amount: z.number(),
  currency: z.string(),
  cancel_at_period_end: z.boolean(),
  interval: z.string(),
  interval_count: z.number(),
  status: z.string(),
  current_period_end: z.number(),
  current_period_start: z.number(),
  name: z.string(),
  last4: z.string().length(4),
  user_type: z.custom<UserType>(), // for:trader(1)
  alerts_only: z.boolean(), // todo: remove nullable
});

export const getInvoiceHistorySchema = z.array(z.custom<Invoice>());
