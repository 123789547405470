import React, { useEffect, useState } from "react";
import "./Accounts.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  useBrokerage,
  useNotification,
  useBilling,
  useBroker,
  useSub,
} from "../../store/store";
import { useIsVisible } from "../../hooks/useIsVisible";
import { BrokerType } from "../../types/types";

const svgIcons = [
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.685 15.5458C9.535 15.6958 9.26833 15.7208 9.09333 15.5458C8.94333 15.3958 8.91833 15.1292 9.09333 14.9542L11.9183 12.1292L10.7433 10.9542L7.91833 13.7792C7.76 13.9458 7.49333 13.9375 7.32666 13.7792C7.15166 13.6042 7.17666 13.3375 7.32666 13.1875L10.1517 10.3625L8.97666 9.1875L6.15166 12.0125C6.00166 12.1625 5.735 12.1875 5.56 12.0125C5.40166 11.8542 5.40166 11.5875 5.56 11.4208L8.385 8.59584L7.20166 7.42084L4.37666 10.2458C4.22666 10.3958 3.96 10.4208 3.785 10.2458C3.62666 10.0792 3.62666 9.82084 3.785 9.65417L7.435 6.00417L8.99333 7.55417C9.785 8.34584 11.1517 8.3375 11.9433 7.55417C12.76 6.7375 12.76 5.42084 11.9433 4.60417L10.3933 3.05417L10.6267 2.82084C11.2767 2.17084 12.335 2.17084 12.985 2.82084L16.5183 6.35417C17.1683 7.00417 17.1683 8.0625 16.5183 8.7125L9.685 15.5458ZM17.6933 9.89584C18.9933 8.59584 18.9933 6.4875 17.6933 5.17917L14.16 1.64584C12.86 0.345837 10.7517 0.345837 9.44333 1.64584L9.21 1.87917L8.97666 1.64584C7.67666 0.345837 5.56833 0.345837 4.26 1.64584L1.31 4.59584C0.126664 5.77917 0.0183302 7.62084 0.976663 8.92084L2.185 7.7125C1.86 7.0875 1.96833 6.29584 2.49333 5.77084L5.44333 2.82084C6.09333 2.17084 7.15166 2.17084 7.80166 2.82084L10.7683 5.7875C10.9183 5.9375 10.9433 6.20417 10.7683 6.37917C10.5933 6.55417 10.3267 6.52917 10.1767 6.37917L7.435 3.64584L2.60166 8.47084C1.785 9.27917 1.785 10.6042 2.60166 11.4208C2.92666 11.7458 3.34333 11.9458 3.785 12.0042C3.84333 12.4375 4.035 12.8542 4.36833 13.1875C4.70166 13.5208 5.11833 13.7125 5.55166 13.7708C5.61 14.2042 5.80166 14.6208 6.135 14.9542C6.46833 15.2875 6.885 15.4792 7.31833 15.5375C7.37666 15.9875 7.57666 16.3958 7.90166 16.7208C8.29333 17.1125 8.81833 17.3292 9.37666 17.3292C9.935 17.3292 10.46 17.1125 10.8517 16.7208L17.6933 9.89584Z"
      fill="black"
    />
  </svg>,
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.49998 17.125C9.41665 17.125 10.1667 16.375 10.1667 15.4583H6.83332C6.83332 16.375 7.58332 17.125 8.49998 17.125ZM13.5 12.125V7.95833C13.5 5.4 12.1417 3.25833 9.74998 2.69167V2.125C9.74998 1.43333 9.19165 0.875 8.49998 0.875C7.80832 0.875 7.24998 1.43333 7.24998 2.125V2.69167C4.86665 3.25833 3.49998 5.39167 3.49998 7.95833V12.125L1.83332 13.7917V14.625H15.1666V13.7917L13.5 12.125ZM11.8333 12.9583H5.16665V7.95833C5.16665 5.89167 6.42498 4.20833 8.49998 4.20833C10.575 4.20833 11.8333 5.89167 11.8333 7.95833V12.9583ZM4.81665 2.19167L3.62498 1C1.62498 2.525 0.308317 4.875 0.19165 7.54167H1.85832C1.98332 5.33333 3.11665 3.4 4.81665 2.19167ZM15.1416 7.54167H16.8083C16.6833 4.875 15.3667 2.525 13.375 1L12.1917 2.19167C13.875 3.4 15.0166 5.33333 15.1416 7.54167Z"
      fill="black"
    />
  </svg>,
];

function Accounts() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState<0 | 1>(0);
  const [sBilling] = useBilling();
  const [sBrokerage] = useBrokerage();
  const [broker] = useBroker();
  const [sSub] = useSub();
  const [sNotification] = useNotification();
  const [, isAccountsVisible, isNotificationVisible] = useIsVisible();

  useEffect(() => {
    const fetchData = async () => {};

    fetchData();
  }, []);

  useEffect(() => {
    const match = () => {
      const url = location.pathname.split("/")[2];
      if (url === "brokerage") return 0;
      if (url === "notification") return 1;
      return 0;
    };

    setSelected(match());
  }, [location.pathname]);

  const handleSelection = (id: 0 | 1) => {
    setSelected(id);
    if (id === 0) navigate("/accounts/brokerage");
    if (id === 1) navigate("/accounts/notification");
  };

  const isBrokerageGreen = () => {
    if (sBrokerage) {
      if (broker === BrokerType.AMERITRADE) {
        return true;
      } else if (broker === BrokerType.INTERACTIVE) {
        return sSub;
      }
    }
    return false;
  };

  return (
    <div className="account-section">
      {!sBilling && (
        <div className="account-section-error-statement">
          You can't access this page until billing completes.
        </div>
      )}
      <div className="account-container">
        <div className="configure-text">Configurations</div>
        <div className="controllers">
          {isAccountsVisible && (
            <div
              className={`btns__ ${!sBilling ? "disableNav" : ""}`}
              id={selected === 0 ? "active-controller" : ""}
              onClick={() => handleSelection(0)}
            >
              <div className="color-buffer">{svgIcons[0]}</div>
              <div className="controlButtons color-buffer">Brokerage</div>
              <div
                className={`radio ${isBrokerageGreen() && "active-green"}`}
              ></div>
            </div>
          )}

          {isNotificationVisible && (
            <div
              className={`btns__ ${!sBilling ? "disableNav" : ""}`}
              id={selected === 1 ? "active-controller" : ""}
              onClick={() => handleSelection(1)}
            >
              <div className="color-buffer">{svgIcons[1]}</div>
              <div className="controlButtons color-buffer">Notification</div>
              <div className={`radio ${sNotification && "active-green"}`}></div>
            </div>
          )}
        </div>
        <div className="hr-line--2"></div>
        <Outlet />
      </div>
    </div>
  );
}

export default Accounts;
