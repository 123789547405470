import React from "react";
import styles from "./Invoice.module.css";
import { Invoice } from "../../../../types/types";
import { getDateFormatted } from "../../../../helper/getDateFormatted.helper";

type InvoiceRowProps = {
  key: number;
  invoiceDetails: Invoice;
};

const InvoiceRow = ({ key: rowId, invoiceDetails }: InvoiceRowProps) => {
  return (
    <div className={styles["table-rows"]}>
      <div className={styles["invoice-number"]}>
        {/* <input id={`checky-box-${rowId}`} type="checkbox" /> */}
        <label htmlFor={`checky-box-${rowId}`}>
          {invoiceDetails.invoice_number
            ? invoiceDetails.invoice_number
            : "In Progress"}
        </label>
      </div>
      <div className={styles["billing-date"]}>
        {getDateFormatted(invoiceDetails.created)}
      </div>
      <div
        className={`${styles["billing-status"]} ${
          invoiceDetails.paid ? styles["paid"] : styles["unpaid"]
        }`}
      >
        {invoiceDetails.paid ? "Paid" : "Unpaid"}
      </div>
      <div className={`${styles["amount"]}`}>
        <span style={{ marginRight: "2px" }}>$</span>
        {invoiceDetails.paid
          ? invoiceDetails.amount_paid
          : invoiceDetails.amount_remaining}
      </div>
      <div className={styles["subs-plaan"]}>{invoiceDetails.name}</div>
      <div className={styles["subs-inv-download"]}>
        <a href={invoiceDetails.receipt_url} rel="no-referrer">
          Download
        </a>
      </div>
    </div>
  );
};

export default InvoiceRow;
