import jwtDecode from "jwt-decode";
import authObj from "../shared/authObject";
import { JwtPayload } from "jwt-decode";

const checkExpiryIdTokenElseLogout = (idToken: string | undefined) => {
  if (idToken) {
    const decodedjwt = jwtDecode<JwtPayload>(idToken);
    if (Date.now() / 1000 <= decodedjwt.exp!) {
      return;
    }
  }

  localStorage.clear();
  const baseUrl = window.location.origin;
  authObj.logoutFunc!({
    logoutParams: {
      returnTo: `${baseUrl}/`,
    },
  });
};

export default checkExpiryIdTokenElseLogout;
