import { UserType } from "../types/types";

export const getUserType = () => {
  switch (localStorage.getItem("userType")) {
    case UserType.CUSTOMER:
      return UserType.CUSTOMER;
    case UserType.TRADER:
      return UserType.TRADER;
    case null:
      throw new Error("User Type Could Not be Found!");
    default:
      throw new Error("User Type Doesnot Match Available Types!");
  }
};
