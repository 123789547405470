import React, { useEffect, useState } from "react";
import { SubscriptionPlan } from "../../../types/types";
import {
  checkout,
  getSubscriptionPlans,
} from "../../../services/api/bills.service";
import { logError } from "../../../helper/logError.helper";
import styles from "./SetupBilling.module.css";
import getNameFromUser from "../../../helper/getNameFromUser.helper";
import { useAuth0 } from "@auth0/auth0-react";

const SetupBilling = () => {
  const { user } = useAuth0();
  const [subscriptionPlans, setSubscriptionPlans] = useState<
    SubscriptionPlan[] | null
  >(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { plans } = await getSubscriptionPlans();
        setSubscriptionPlans(plans);
      } catch (err) {
        logError(err);
      }
    };

    fetchData();
  }, []);

  const checkoutOnClick = async (plan: SubscriptionPlan) => {
    try {
      const { url } = await checkout(plan.price.id);
      window.location.href = url;
    } catch (err) {
      console.log(err);
      logError(err);
    }
  };

  return (
    <div className={styles["plans-container"]}>
      <div className={styles["plan-headers"]}>Choose the Best Plan for you</div>
      <div className={styles["plan-wrapper"]}>
        {subscriptionPlans &&
          subscriptionPlans.map((plan) => (
            <div className={styles["plan-info"]} key={plan.id}>
              <div className={styles["plan-name"]}>{plan.name}</div>
              <div className={styles["break-line"]} />
              <div className={styles["plan-price"]}>
                ${plan.price.unit_amount / 100}
              </div>
              <div className={styles["plan-type"]}>
                Per {plan.price.recurring.interval}
              </div>
              <div className={styles["hr-line"]} />
              <div className={styles["submit-btn"]}>
                <button onClick={() => checkoutOnClick(plan)}>
                  SELECT PLAN
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SetupBilling;
