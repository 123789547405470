export const normalizeInput = (value: string) => {
  if (!value.length) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (cvLength < 4) return currentValue;
  else if (cvLength < 7)
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  else
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
};

export const validatePhoneInput = (value: string) => {
  let error = "";

  if (!value) error = "Required!";
  else if (value.length !== 14)
    error = "Invalid phone format. ex: (555) 555-5555";

  if (error !== "") throw new Error(error);
};

export const convertPhoneToString = (value: string) => {
  validatePhoneInput(value);
  let normValue = `${value.substring(1, 4)}${value.substring(
    6,
    9
  )}${value.substring(10, 14)}`;

  return normValue;
};
