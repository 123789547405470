import React from "react";
import "./Icons.css";
import { useIsVisible } from "../../../../hooks/useIsVisible";
import { TabsName } from "../Menu";

type IconsProps = {
  className: string;
  iconInfo: {
    name: string;
    icon: JSX.Element;
    path: string;
  };
  onClick: () => void;
};

function Icons(props: IconsProps) {
  const iconInfo = props.iconInfo;

  const [isBillingVisible, isAccountsVisible, isNotificationVisible] =
    useIsVisible();

  const isVisible = () => {
    if (iconInfo.name === TabsName.DASHBOARD) return true;
    if (iconInfo.name === TabsName.BILLING && isBillingVisible) return true;
    if (iconInfo.name === TabsName.ACCOUNTS && isAccountsVisible) return true;
    if (
      iconInfo.name === TabsName.NOTIFICATION &&
      !isAccountsVisible &&
      isNotificationVisible
    )
      return true;
    return false;
  };

  return isVisible() ? (
    <div
      className={`icon-container ${props.className}`}
      onClick={props.onClick}
    >
      <div className="icon-img">{iconInfo.icon}</div>
      <div className="icon-text">{iconInfo.name}</div>
    </div>
  ) : (
    <></>
  );
}

export default Icons;
