import React, { useEffect, useState } from "react";
import "./AvailableCard.css";
import {
  BrokerageAccount,
  IAccountsFunction,
  SubscriptionStatus,
} from "../../../../../types/types";
import { logError } from "../../../../../helper/logError.helper";
import { useAccountId, useBroker, useSub } from "../../../../../store/store";
import { getUserType } from "../../../../../helper/userType.helper";
import { getAccountsFunction } from "../../../../../shared/accountFunction";

const svgIcon = (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 8.11765L5.88372 12L15 1" stroke="white" stroke-width="2" />
  </svg>
);

type AvailableCardProps = {
  brokerageAccount: BrokerageAccount;
};

const AvailableCard = ({ brokerageAccount }: AvailableCardProps) => {
  const [accountId] = useAccountId();
  const [broker] = useBroker();
  const [sub, setSub] = useSub();
  const [selected, setSelected] = useState(true);
  const [userType] = useState(getUserType());

  const accountsFunction = broker && getAccountsFunction(broker);

  const subStatus = sub
    ? SubscriptionStatus.SUBSCRIBED
    : SubscriptionStatus.UNSUBSCRIBED;

  useEffect(() => {
    const fetchData = async () => {};
    fetchData();
  }, []);

  const subscribeOnClick = async () => {
    try {
      if (!accountId || !accountsFunction) return;
      // subscribe here (check if already subscibed)
      if (subStatus === SubscriptionStatus.SUBSCRIBED) return;

      await accountsFunction.startTrade(accountId);
      setSub(SubscriptionStatus.SUBSCRIBED);
      setSelected(false);
    } catch (err) {
      logError(err);
    }
  };

  const unsubscribeOnClick = async () => {
    try {
      if (!accountId || !accountsFunction) return;
      // unsubscibe (check if already unsubscribed)
      if (subStatus === SubscriptionStatus.UNSUBSCRIBED) return;

      await accountsFunction.stopTrade(accountId);
      setSub(SubscriptionStatus.UNSUBSCRIBED);
      setSelected(false);
    } catch (err) {
      logError(err);
    }
  };

  const toggleSelectedState = () => {
    setSelected(!selected);
  };

  return (
    <div className={`available__cards ${selected && "active-available-card"}`}>
      <div className="avail-_card__wrapper" onClick={toggleSelectedState}>
        <div className="info-_container__box_">
          <div className="radio_box--rounded">
            <div className="spherical_radio-_btn">
              <div className="checkbox_svg__icon">{svgIcon}</div>
            </div>
          </div>
          <div className="account-_info">
            <div className="text-_style">
              {brokerageAccount.BrokerageAccountId}
            </div>
            <div className="type-_text__">Account ID</div>
          </div>
          <div className="acct_-type">
            <div className="text-_style">
              {brokerageAccount.AccountType.charAt(0).toUpperCase() +
                brokerageAccount.AccountType.slice(1).toLowerCase()}
            </div>
            <div className="type-_text__">Account Type</div>
          </div>
        </div>
        <div className="balance-_sheet">
          <div>$ {brokerageAccount.AccountValue}</div>
          <div className="type-_text__">Account Value</div>
        </div>
      </div>

      {subStatus === SubscriptionStatus.UNSUBSCRIBED && !selected && (
        <div className="available_card-tag">Not Subscribed</div>
      )}

      {subStatus === SubscriptionStatus.UNSUBSCRIBED && selected && (
        <div className="account-confirmation">
          <button onClick={subscribeOnClick}>Subscribe Account</button>
        </div>
      )}

      {subStatus === SubscriptionStatus.SUBSCRIBED && !selected && (
        <div className="subscribed-as">Subscribed</div>
      )}

      {subStatus === SubscriptionStatus.SUBSCRIBED && selected && (
        <div className="account-confirmation">
          <button onClick={unsubscribeOnClick}>Unsubscribe</button>
        </div>
      )}
    </div>
  );
};

export default AvailableCard;
