import React from "react";
import styles from "../Dashboard.module.css";
import {
  useBilling,
  useBroker,
  useBrokerage,
  useIBKRStatuses,
  usePrimaryAccountId,
  useSub,
} from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { BrokerType } from "../../../types/types";

const AccountsDashboard = () => {
  const [sBrokerage] = useBrokerage();
  const [sBilling] = useBilling();
  const [sSub] = useSub();
  const [broker] = useBroker();
  const [ibkrStatuses] = useIBKRStatuses();
  const [primaryAccountId] = usePrimaryAccountId();
  const navigate = useNavigate();

  const getStatusText = (status: boolean, text: string) => {
    return !status ? (sBilling ? "Not " + text : "Complete Billing") : text;
  };

  return (
    <div
      className={`${styles["configure-tab"]} ${
        sSub ? styles["outline-success"] : styles["outline-fail"]
      }`}
    >
      <h2 className={styles["box-header"]}>Brokerage Configuration</h2>
      <div className={styles["configured-info"]}>
        <div className={styles["configure-metadata-1"]}>
          <div className={styles["auth-props"]}>Authentication</div>
          <div className={styles["configure-status"]}>
            <div className={styles["configure-props"]}>
              {getStatusText(sBrokerage, "Configured")}
            </div>
            <div
              className={`${styles["configure-dot"]} ${
                sBrokerage ? styles["green-dot"] : styles["red-dot"]
              }`}
            ></div>
          </div>
          <div className={styles["configure-value"]}>Status</div>
        </div>
        <div className={styles["configure-metadata-2"]}>
          <div className={styles["configure-props"]}>
            {sBrokerage &&
            broker &&
            (broker !== BrokerType.INTERACTIVE || ibkrStatuses.authentication)
              ? broker
              : "N/A"}
          </div>
          <div className={styles["configure-value"]}>Brokerage</div>
        </div>
        <div className={styles["configure-metadata-3"]}>
          <a href="!#" rel="no-referrer" style={{ display: "none" }}>
            Hidden
          </a>
        </div>
      </div>
      <div className={styles["configured-info"]}>
        <div className={styles["configure-metadata-1"]}>
          <div className={`${styles["auth-props"]} ${styles["auth--2"]}`}>
            Subscription
          </div>
          <div className={styles["configure-status"]}>
            <div className={styles["configure-props"]}>
              {getStatusText(sSub, "Subscribed")}
            </div>
            <div
              className={`${styles["configure-dot"]} ${
                sSub ? styles["green-dot"] : styles["red-dot"]
              }`}
            ></div>
          </div>
          <div className={styles["configure-value"]}>Status</div>
        </div>
        <div className={styles["configure-metadata-2"]}>
          <div className={styles["configure-props"]}>
            {sSub && primaryAccountId ? primaryAccountId : "N/A"}
          </div>
          <div className={styles["configure-value"]}>Account</div>
        </div>
        <div className={styles["configure-metadata-3"]}>
          {sBilling && (
            <button
              onClick={() => navigate("/accounts/brokerage")}
              className={styles["action-btn"]}
            >
              Go to Brokerage
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountsDashboard;
