import { z } from "zod";
import {
  AuthStatus,
  BrokerageAccount,
  HostStatus,
  SubscriptionStatus,
  UserType,
} from "../types";

export const AuthorizeAmeritradeSchema = z.object({
  Url: z.string(),
});

export const getBrokerageAccountsSchema = z.object({
  accountId: z.string(),
  brokerageAccounts: z.custom<BrokerageAccount[]>(),
});

export const subscriptionStatusSchema = z.object({
  status: z.custom<SubscriptionStatus>(),
  userType: z.custom<UserType>(),
});

export const AuthorizeIBKRSchema = z.object({
  authStatus: z.custom<AuthStatus>(),
  accountId: z.string().nullable(),
  status: z.custom<SubscriptionStatus>(),
  hostStatus: z.custom<HostStatus>(),
  userType: z.custom<UserType>(),
});

export const ibStatusSchema = z.object({
  accountId: z.string().nullable(),
  status: z.custom<SubscriptionStatus>(),
  hostStatus: z.custom<HostStatus>(),
  userType: z.custom<UserType>(),
});
