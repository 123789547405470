import { User } from "@auth0/auth0-spa-js";
import { NotificationType } from "../../types/types";

export const getAuthorizeToSlackInput = (user: User, accountId: string) => {
  return { primaryAccountId: accountId, email: user.email };
};

export const getUnauthorizeToSlackInput = (accountId: string) => {
  return { accountId };
};

export const getCheckSlackAuthStatusInput = (accountId: string) => {
  return { accountId };
};

export const getSlackUserExistsInput = (accountId: string, user: User) => {
  return { accountId, email: user.email };
};

export const getSlackNewUserJoinRequestInput = (
  accountId: string,
  user: User
) => {
  return { accountId, email: user.email };
};

export const getNotificationEnableOrUpdateInput = (
  accountId: string,
  notificationType: NotificationType,
  phone: string
) => {
  let payload: {
    accountId: string;
    notificationType: NotificationType;
    phone?: string;
  } = { accountId, notificationType };

  if (notificationType === NotificationType.SMS) {
    if (phone === "") throw new Error("Phone Number not found");
    payload.phone = phone;
  }
  return payload;
};

export const getNotificationDisableInput = (accountId: string) => {
  return { accountId };
};

export const getNotificationStateCheckInput = (accountId: string) => {
  return { accountId };
};

export const getDeletePortfolioInput = (accountId: string) => {
  return { accountId };
};
