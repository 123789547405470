import * as inputs from "../../helper/api/alerts.helper";
import * as schema from "../../types/response/alerts.type";
import { NotificationType } from "../../types/types";
import { customAxios } from "../axios.service";

// 1
export const enableAlert = async (
  notificationType: NotificationType,
  phone: string
) => {
  const formData = inputs.getAlertEnableOrUpdateInput(notificationType, phone);
  const { data } = await customAxios.notification.post(
    "alerts/enable",
    formData
  );

  return data;
};

// 2
export const updateAlert = async (
  notificationType: NotificationType,
  phone: string
) => {
  const formData = inputs.getAlertEnableOrUpdateInput(notificationType, phone);
  const { data } = await customAxios.notification.post(
    "alerts/update",
    formData
  );

  return data;
};

// 3
export const disableAlert = async () => {
  const { data } = await customAxios.notification.delete("alerts/disable");
  return data;
};

// 4
export const getAlertStatus = async () => {
  const { data } = await customAxios.notification.get("alerts/status");
  let d = schema.getAlertStatusSchema.parse(data);

  return d;
};
